import React, { Component } from 'react'
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import Cookies from 'js-cookie';
import { jsPDF } from 'jspdf';
import 'jspdf/dist/polyfills.es.js'; // Required to support Internet Explorer

import './css/bootstrap.min.css';
import './css/main-body.css';
import './css/main-canvas.css';
import './css/main-view.css';
import './css/main-loading.css';
import './css/main-start.css';
import './css/main-intro.css';
import './css/main-configurator.css';
import './css/main-function.css';
import './css/main-popup.css';
import './css/main-form.css';
import './css/main-footer.css';
import './css/main-warning.css';
import './css/main-favourites.css';
import './css/main-debug.css';

import './fonts/ClanProRegular-normal.js';
import './fonts/ClanProMedium-normal.js';

import Canvas from './components/Canvas';
import { LoadingIntro, LoadingFavourite } from './components/Loading';
import Start from './components/Start';
import Intro from './components/Intro';
import Configurator from './components/Configurator';
import View from './components/View';
import Function from './components/Function';
import Popup from './components/Popup';
import Footer from './components/Footer';
import Warning from './components/Warning';
import Favourites from './components/Favourites';
import Download from './components/Download' 
import Debug from './components/Debug';

export default class App extends Component
{
    constructor(props)
    {
        // Props
        super(props);

        // State
        this.state = {

            // Canvas
            canvas_width: window.innerWidth,
            //canvas_height: window.innerHeight-80,
            canvas_height: 600,
            canvas_queued_resize: false, // Flag if a resize is queued

            // Intro format: [id, collection_id, stone1_id, stone2_id, stone3_id, stone4_id, stone5_id, stone6_id, stone7_id, stone8_id, spacing_id, spacing_color_id, bond_type_id, bond_orientation_id, displayname_nl, displayname_fr, label_active, label_nl, label_fr, label_hightlight]
            content_intro: [],
            
            // Stone collection format: [id, name, displayname_nl, displayname_fr, length, height, width_brick, height_brick]
            content_stone_collections: [],
            
            // Stone type format: [name, collection_id, displayname_nl, displayname_fr, article_id_strip, article_id_strip_corner, article_id_stone]
            content_stone_types: [],
            content_stone_loaded_counter: 0, // Debug helper
            content_stone_loaded_target: 0, // Debug helper
            
            // Spacing types: [name, displayname_nl, displayname_fr, spacing_h_length, spacing_h_darken, spacing_v_length, spacing_v_darken]
            content_spacing_types: [],
            
            // Spacing colors format: [hexcolor, displayname_nl, displayname_fr]
            content_spacing_colors: [],
            
            // Bond type format: [name, displayname_nl, displayname_fr]
            content_bond_types: [],
            
            // Bond orientation format: [name, displayname_nl, displayname_fr]
            content_bond_orientations: [],
            
            // Photo format: [name, displayname_nl, displayname_fr]
            content_photos: [],

            // Translation format: [key, language_nl, language_fr]
            content_translations: [],

            // Form clients format: [displayname_nl, displayname_fr]
            content_form_clients: [],
            
            content_called: false,
            content_loaded: false,

            // Intro
            intro_loaded_counter: 0,
            intro_loaded_target: 0,
            intro_loaded: false,

            // Photo
            photo_current: null,
            photo_size: 0,
            photo_loaded_counter: 0,
            photo_loaded_target: 0,
            photo_loaded: false,
            photo_first: true,

            // Configurator
            configurator_intro: true,
            configurator_intro_start: false,
            configurator_stone_collection: null,
            configurator_stone_multi: [], // Array with one index per stones
            configurator_spacing_type: null,
            configurator_spacing_color: null,
            configurator_bond_type: null,
            configurator_bond_orientation: null,

            configurator_changed: false, // Tracks if the user made a change
            configurator_mobile: null, // Mobile menu helper
            
            // Autosave
            autosave_loaded_counter: 0,
            autosave_loaded_target: 0,
            autosave_loaded: false,
            
            // View
            view_mode: 0,
            view_rows_min: 8,
            view_rows_max: 48,
            view_rows_increment: 4,
            view_rows: 24,

            // Window
            window_height: 0,

            // Favourites
            favourites_loaded_counter: 0,
            favourites_loaded_target: 0,
            favourites_loaded: false,

            // Debug
            debug_stone_width_full: 0,
            debug_stone_width_half: 0,
            debug_stone_height: 0,
            debug_stone_count: 0,
            debug_page_width_mm: 0,
            debug_page_height_mm: 0,
            debug_page_text_count: 0,
            debug_canvas_render: 0,
        };
        
        // Translation
        this.translation_language_index = 0;
        if (window.location.origin === 'https://generateurdetextures.facade.stone-style.ebema.be')
        {
            this.translation_language_index = 1;
            document.documentElement.lang = 'fr-BE';
        }

        this.translation_nl = {};
        this.translation_fr = {};
        this.translate = this.translate.bind(this);

        // Canvas
        this.canvas_reference = React.createRef();
        this.canvas_queue_resize = this.canvas_queue_resize.bind(this);
        this.canvas_resize = this.canvas_resize.bind(this);
        this.canvas_render = this.canvas_render.bind(this);

        // Content
        this.content_intro_loaded = false;
        this.content_stone_collections_loaded = false;
        this.content_stone_types_loaded = false;
        this.content_spacing_types_loaded = false;
        this.content_spacing_colors_loaded = false;
        this.content_bond_types_loaded = false;
        this.content_bond_orientations_loaded = false;
        this.content_photos_loaded = false;
        this.content_translations_loaded = false;
        this.content_form_clients_loaded = false;

        this.content_get_intro = this.content_get_intro.bind(this);
        this.content_get_stone_collections = this.content_get_stone_collections.bind(this);
        this.content_get_stone_types = this.content_get_stone_types.bind(this);
        this.content_get_spacing_types = this.content_get_spacing_types.bind(this);
        this.content_get_spacing_colors = this.content_get_spacing_colors.bind(this);
        this.content_get_bond_types = this.content_get_bond_types.bind(this);
        this.content_get_bond_orientations = this.content_get_bond_orientations.bind(this);
        this.content_get_photos = this.content_get_photos.bind(this);
        this.content_get_translations = this.content_get_translations.bind(this);
        this.content_get_form_clients = this.content_get_form_clients.bind(this);
        this.content_check_loaded = this.content_check_loaded.bind(this);
        
        // Startup
        this.startup = this.startup.bind(this);

        // Intro
        this.intro_callback_counter = 0;
        this.intro_diffuse_source = [];
        this.intro_diffuse_image = [];
        this.intro_mixmask_source = [];
        this.intro_mixmask_image = [];
        this.intro_stones = [];

        this.intro_prepare_textures = this.intro_prepare_textures.bind(this);
        this.intro_load_textures = this.intro_load_textures.bind(this);
        this.intro_callback_loaded_texture = this.intro_callback_loaded_texture.bind(this);
        this.intro_start_existing = this.intro_start_existing.bind(this);

        // Stone
        this.stone_callback_counter = 0;
        this.stone_callback_target = 0;
        this.stone_full_source = [];
        this.stone_full_image = [];
        this.stone_half_source = [];
        this.stone_half_image = [];
        this.stone_enabled = [];
        this.stone_blanco_full_source = [];
        this.stone_blanco_full_image = [];
        this.stone_blanco_half_source = [];
        this.stone_blanco_half_image = [];
        this.stone_blanco_enabled = [];
        this.stone_prepare_textures = this.stone_prepare_textures.bind(this);
        this.stone_load_textures = this.stone_load_textures.bind(this);
        this.stone_callback_loaded_texture = this.stone_callback_loaded_texture.bind(this);
        this.stone_check_textures_loaded = this.stone_check_textures_loaded.bind(this);
        
        // Photo
        this.photo_callback_counter = 0;
        this.photo_base_source = null;
        this.photo_base_image = null;
        this.photo_mask_source = null;
        this.photo_mask_image = null;
        this.photo_dirt_source = null;
        this.photo_dirt_image = null;
        this.photo_prepare_textures = this.photo_prepare_textures.bind(this);
        this.photo_load_textures = this.photo_load_textures.bind(this);
        this.photo_callback_loaded_texture = this.photo_callback_loaded_texture.bind(this);
        this.photo_delete_textures = this.photo_delete_textures.bind(this);
        
        // Photolayer
        this.photolayer_diffuse_source = [];
        this.photolayer_diffuse_image = [];
        this.photolayer_lighting_source = null;
        this.photolayer_lighting_image = null;
        this.photolayer_mask_source = null;
        this.photolayer_mask_image = null;
        this.photolayer_mixmask_source = [];
        this.photolayer_mixmask_image = [];
        
        // Configurator
        this.configurator_start = this.configurator_start.bind(this);
        this.configurator_start_blanco = this.configurator_start_blanco.bind(this);
        this.configurator_start_autosave = this.configurator_start_autosave.bind(this);
        this.configurator_start_continue = this.configurator_start_continue.bind(this);
        this.configurator_stone_collection = this.configurator_stone_collection.bind(this);
        this.configurator_stone_select_multi_increase = this.configurator_stone_select_multi_increase.bind(this);
        this.configurator_stone_select_multi_decrease = this.configurator_stone_select_multi_decrease.bind(this);
        this.configurator_spacing_type = this.configurator_spacing_type.bind(this);
        this.configurator_spacing_color = this.configurator_spacing_color.bind(this);
        this.configurator_bond_type = this.configurator_bond_type.bind(this);
        this.configurator_bond_orientation = this.configurator_bond_orientation.bind(this);
        this.configurator_enable_mobile = this.configurator_enable_mobile.bind(this);
        this.configurator_disable_mobile = this.configurator_disable_mobile.bind(this);
        this.configurator_adapt_dropdowns = this.configurator_adapt_dropdowns.bind(this);
        this.configurator_check_valid = this.configurator_check_valid.bind(this);
        this.configurator_get_querystring = this.configurator_get_querystring.bind(this);
        
        // Cookie
        this.cookie_stone_collection = null;
        this.cookie_stone = [];
        this.cookie_spacing_type = null;
        this.cookie_spacing_color = null;
        this.cookie_bond_type = null;
        this.cookie_bond_orientation = null;
        this.cookie_date = null;
        this.cookie_read = this.cookie_read.bind(this);
        
        // Autosave
        this.autosave_found = false;
        this.autosave_stone_collection = null;
        this.autosave_stone = null;
        this.autosave_spacing_type = null;
        this.autosave_spacing_color = null;
        this.autosave_bond_type = null;
        this.autosave_bond_orientation = null;
        this.autosave_date = null;

        this.autosave_callback_counter = 0;
        this.autosave_diffuse_source = [];
        this.autosave_diffuse_image = [];
        this.autosave_mixmask_source = [];
        this.autosave_mixmask_image = [];
        this.autosave_stones = [];

        this.autosave_save_cookie = this.autosave_save_cookie.bind(this);
        this.autosave_read_cookie = this.autosave_read_cookie.bind(this);
        this.autosave_prepare_textures = this.autosave_prepare_textures.bind(this);
        this.autosave_load_textures = this.autosave_load_textures.bind(this);
        this.autosave_callback_loaded_texture = this.autosave_callback_loaded_texture.bind(this);

        // View
        this.view_mode = this.view_mode.bind(this);
        this.view_rows_zoomin = this.view_rows_zoomin.bind(this);
        this.view_rows_zoomout = this.view_rows_zoomout.bind(this);
        this.view_back_to_intro_items = this.view_back_to_intro_items.bind(this);
        
        // Photo
        this.photo_select = this.photo_select.bind(this);
        this.photo_previous = this.photo_previous.bind(this);
        this.photo_next = this.photo_next.bind(this);
        this.photo_size = this.photo_size.bind(this);

        // Popup
        this.popup_open = this.popup_open.bind(this);
        this.popup_close = this.popup_close.bind(this);

        // Debug
        this.debug = false;

        // IP
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
            this.ip = 'http://127.0.0.1:8000';
        else
            this.ip = window.location.origin;

        if (this.debug === true)
            console.log('IP: '+this.ip);

        // Message
        this.message_footer_height = null; // Outgoing message with the footer height

        this.message_incoming = this.message_incoming.bind(this);
        this.message_post_request_window_height = this.message_post_request_window_height.bind(this);
        this.message_post_height = this.message_post_height.bind(this);
        this.message_post_querystring = this.message_post_querystring.bind(this);
        this.message_post_favourite = this.message_post_favourite.bind(this);
        
        // Favourites
        this.favourites = false;
        if (window.location.pathname === '/favorieten' || window.location.pathname === '/favoris')
            this.favourites = true;

        this.favourites_key = [];
        this.favourites_stone_collection = [];
        this.favourites_stone = [];
        this.favourites_spacing_type = [];
        this.favourites_spacing_color = [];
        this.favourites_bond_type = [];
        this.favourites_bond_orientation = [];
        this.favourites_date = [];

        this.favourites_callback_counter = 0;
        this.favourites_diffuse_source = [];
        this.favourites_diffuse_image = [];
        this.favourites_mixmask_source = [];
        this.favourites_mixmask_image = [];
        this.favourites_stones = [];

        this.favourites_save_cookie = this.favourites_save_cookie.bind(this);
        this.favourites_read_cookies = this.favourites_read_cookies.bind(this);
        this.favourites_prepare_textures = this.favourites_prepare_textures.bind(this);
        this.favourites_load_textures = this.favourites_load_textures.bind(this);
        this.favourites_callback_loaded_texture = this.favourites_callback_loaded_texture.bind(this);
        this.favourites_delete_cookie = this.favourites_delete_cookie.bind(this);

        // Exports
        this.export_filename = this.export_filename.bind(this);
        this.export_pdf = this.export_pdf.bind(this);

        // Download
        this.download = false;
        if (window.location.pathname === '/download')
            this.download = true;

        // Tracker
        this.tracker_pdf = this.tracker_pdf.bind(this);
    }

    // -------------------------------------------------------------------

    // Translate
    translate(key)
    {
        switch (this.translation_language_index)
        {
            case 0: return this.translation_nl[key];
            case 1: return this.translation_fr[key];
            default: return this.translation_nl[key];
        }
    }

    // -------------------------------------------------------------------
    
    // Canvas queue resize
    canvas_queue_resize()
    {
        if (this.state.canvas_queued_resize === false)
        {
            this.setState({
                canvas_queued_resize: true
            }, () => {
                // Create timer
                const timer = setTimeout(() => {

                    // Cleanup timer
                    clearTimeout(timer);
                    if (window.top !== window.self)
                    {
                        this.setState({
                            canvas_queued_resize: false
                        }, () => this.canvas_resize());
                    }
                    else
                    {
                        this.setState({
                            canvas_queued_resize: false,
                            window_height: window.innerHeight
                        }, () => this.canvas_resize());
                    }
                }, 500);
            });
        }
    }

    // Canvas resize
    canvas_resize()
    {
        const canvas = document.getElementById('canvas_id');

        let width = window.innerWidth*2;
        let height = (this.state.window_height)*2;

        if (this.state.view_mode === 1)
        {
            width = window.innerWidth*2;
            height = (this.state.window_height-72)*2;
        }

        canvas.width = width;
        canvas.height = height;

        this.setState({
            canvas_width: width,
            canvas_height: height
        }, () => this.canvas_render());

        if (this.state.configurator_intro === false)
            this.configurator_adapt_dropdowns();

        this.message_post_height();
    }

    // Canvas render
    canvas_render(override_canvas, override_canvas_width, override_canvas_height, override_view_mode, override_view_rows)
    {
        let canvas_id = this.canvas_reference.current;
        let canvas_width = this.state.canvas_width;
        let canvas_height = this.state.canvas_height;
        let canvas_view_mode = this.state.view_mode;

        // Implement overrides for the PDF exports
        if (typeof override_canvas !== 'undefined')
            canvas_id = override_canvas;

        if (typeof override_canvas !== 'undefined')
            canvas_width = override_canvas_width;

        if (typeof override_canvas !== 'undefined')
            canvas_height = override_canvas_height;

        if (typeof override_view_mode !== 'undefined')
            canvas_view_mode = override_view_mode;
        
        if (this.configurator_check_valid() === true)
        {
            // Failsafe
            if (this.state.content_loaded === true)
            {
                if (canvas_view_mode === 0)
                {
                    // Stone ----------------------------------------------------------------------
                    
                    if (this.stone_check_textures_loaded() === true)
                    {
                        let view_rows = this.state.view_rows;

                        if (typeof override_view_rows !== 'undefined')
                            view_rows = override_view_rows;

                        const context = canvas_id.getContext('2d');

                        // Offset due to measurement bars
                        let offset_x = 32;
                        let offset_y = 32;

                        // Stone + spacing size in mm

                        let stone_width_full_mm = this.state.content_stone_collections[this.state.configurator_stone_collection][4];
                        let stone_width_half_mm = (stone_width_full_mm-this.state.content_spacing_types[this.state.configurator_spacing_type][3])*0.5;
                        let stone_height_mm = this.state.content_stone_collections[this.state.configurator_stone_collection][5];
                        let spacing_width_mm = this.state.content_spacing_types[this.state.configurator_spacing_type][3];
                        let spacing_height_mm = this.state.content_spacing_types[this.state.configurator_spacing_type][5];
                        let total_height_mm = Math.max(stone_height_mm+spacing_height_mm, 1);
                        
                        // Rows
                        if (this.state.configurator_bond_orientation === 1)
                        {
                            // Modify for vertical bonds
                            view_rows = Math.floor(view_rows*(canvas_width/Math.max(canvas_height, 1)));
                        }

                        // Row height
                        let row_height;
                        if (this.state.configurator_bond_orientation === 0)
                        {
                            // Horizontal bonds
                            row_height = (canvas_height-offset_y)/view_rows;
                        }
                        else
                        {
                            // Vertical bonds
                            row_height = (canvas_width-offset_x)/view_rows;
                        }

                        // Stone/spacing height
                        let stone_height = Math.max((stone_height_mm/total_height_mm)*row_height, 1);
                        let spacing_height = 0;
                        if (spacing_height_mm > 0)
                        {
                            spacing_height = (spacing_height_mm/stone_height_mm)*stone_height;
                        }

                        // Stone/spacing width
                        let stone_width_full = stone_height*(stone_width_full_mm/stone_height_mm);
                        let stone_width_half = stone_height*(stone_width_half_mm/stone_height_mm);
                        
                        let spacing_width = 0;
                        if (spacing_width_mm > 0)
                        {
                            spacing_width = (spacing_width_mm/stone_width_full_mm)*stone_width_full;
                        }

                        // Stone/spacing total
                        let total_width_full = stone_width_full+spacing_width;
                        let total_width_half = stone_width_half+spacing_width;
                        let total_height = stone_height+spacing_height;
                        
                        // Full page size in mm
                        let page_height_mm;
                        let page_width_mm;
                        if (this.state.configurator_bond_orientation === 0)
                        {
                            page_height_mm = total_height_mm*(view_rows);
                            page_width_mm = page_height_mm*((canvas_width-offset_x)/Math.max(canvas_height-offset_y, 1));
                        }
                        else
                        {
                            page_width_mm = view_rows*total_height_mm;
                            page_height_mm = page_width_mm/((canvas_width-offset_x)/Math.max(canvas_height-offset_y, 1));
                        }

                        // Stone image object list (at this point all of the necessary ones are loaded)
                        const stone_image_indices = [];
                        for (let i = 0; i < this.state.content_stone_types.length; i++)
                        {
                            // Check if this stone is in the current collection
                            if (this.state.content_stone_types[i][1] === this.state.content_stone_collections[this.state.configurator_stone_collection][0])
                            {
                                // Implement the stone factors
                                for (let repeat = 0; repeat < this.state.configurator_stone_multi[i]; repeat++)
                                {
                                    for (let j = 0; j < 7; j++)
                                    {
                                        let index = (i*7)+j;
                                        stone_image_indices.push(index);
                                    }
                                }
                            }
                        }

                        // Function to pick a random stone from the list
                        function stone_get_random_index()
                        {
                            let random_index = Math.floor(Math.random() * stone_image_indices.length);
                            return stone_image_indices[random_index];
                        }

                        function stone_get_random_index_blanco()
                        {
                            return Math.floor(Math.random() * 7);
                        }
                        
                        // Clear
                        let fill_color = '';
                        switch (this.state.configurator_spacing_type)
                        {
                            case 0:     fill_color = '#000000'; break;
                            case 1:     fill_color = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0]; break;
                            case 2:     fill_color = '#000000'; break;

                            default:    fill_color = '#000000'; break;
                        }
                        context.fillStyle = fill_color;
                        context.fillRect(0, 0, canvas_width, canvas_height);
                        
                        // Draw stones
                        let stone_count = 0;

                        function stone_draw_full(index, x, y, w, h, rotate, image, blanco, blanco_index, blanco_image)
                        {
                            let im;
                            if (blanco === false)
                                im = image[index];
                            else
                                im = blanco_image[blanco_index];

                            let scale_w = w / im.width;
                            let scale_h = h / im.height;

                            if (Math.round(Math.random()) === 1) { scale_w = -scale_w; }
                            if (Math.round(Math.random()) === 1) { scale_h = -scale_h; }
                            
                            context.setTransform(scale_w, 0, 0, scale_h, x, y);

                            if (rotate === true)
                                context.rotate(90*Math.PI/180);
                            
                            context.drawImage(im, -im.width*0.5, -im.height*0.5);
                        }

                        function stone_draw_half(index, x, y, w, h, rotate, image, blanco, blanco_index, blanco_image)
                        {
                            let im;
                            if (blanco === false)
                                im = image[index];
                            else
                                im = blanco_image[blanco_index];
                            
                            let scale_w = w / im.width;
                            let scale_h = h / im.height;

                            if (Math.round(Math.random()) === 1) { scale_w = -scale_w; }
                            if (Math.round(Math.random()) === 1) { scale_h = -scale_h; }
                            
                            context.setTransform(scale_w, 0, 0, scale_h, x, y);

                            if (rotate === true)
                                context.rotate(90*Math.PI/180);
                            
                            context.drawImage(im, -im.width*0.5, -im.height*0.5);
                        }

                        context.save();
                        
                        let w_full;
                        let w_half;
                        let h;

                        // Blanco
                        let total = 0;
                        for (let i = 0; i < this.state.content_stone_types.length; i++)
                        {
                            // Check if this stone is in the current collection
                            if (this.state.content_stone_types[i][1] === this.state.content_stone_collections[this.state.configurator_stone_collection][0])
                            {
                                if (this.state.configurator_stone_multi[i] > 0)
                                    total += this.state.configurator_stone_multi[i];
                            }
                        }

                        let blanco = true;
                        if (total > 0)
                            blanco = false;
                        
                        switch (this.state.content_bond_types[this.state.configurator_bond_type][0])
                        {
                            // ----------------------------------------------------------------

                            // Halfsteensverband
                            case 'halfsteensverband':
                                
                            w_full = stone_height*(stone_width_full_mm/stone_height_mm);
                            h = stone_height;
                            
                            if (this.state.configurator_bond_orientation === 0)
                            {
                                // Horizontal
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(0, base_y+(total_height-spacing_height-2), canvas_width, spacing_height+4);
                                    }
                                }
                                
                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_x = offset_x-((row % 2)*total_width_full*0.5);
                                    let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                    for (let x = base_x; x < canvas_width; x += total_width_full)
                                    {
                                        stone_draw_full(stone_get_random_index(), x+stone_width_full*0.5, base_y+stone_height*0.5, w_full, h, false, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                        stone_count++;
                                    }
                                }
                            }
                            else
                            {
                                // Vertical
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_x = offset_x+(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(base_x+(total_height-spacing_height-2), 0, spacing_height+4, canvas_height);
                                    }
                                }

                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_x = offset_x+(row*total_height);
                                    let base_y = canvas_height-offset_y+((row % 2)*total_width_full*0.5);
                                
                                    for (let y = base_y; y > 0; y -= total_width_full)
                                    {
                                        stone_draw_full(stone_get_random_index(), base_x+stone_height*0.5, y-stone_width_full*0.5, w_full, h, true, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);
                                        
                                        stone_count++;
                                    }
                                }
                            }

                            break;

                            // ----------------------------------------------------------------

                            // Kettingverband
                            case 'kettingverband':

                            w_full = stone_height*(stone_width_full_mm/stone_height_mm);
                            w_half = stone_height*(stone_width_half_mm/stone_height_mm);
                            h = stone_height;
                            
                            if (this.state.configurator_bond_orientation === 0)
                            {
                                // Horizontal
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(0, base_y+(total_height-spacing_height-2), canvas_width, spacing_height+4);
                                    }
                                }

                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_x = offset_x-(row % 2)*((total_width_full+total_width_full+total_width_half)*0.5);
                                    let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                    let type_counter = -1;
                                    let last_stone_width;

                                    for (let x = base_x; x < canvas_width; x += last_stone_width)
                                    {
                                        type_counter = (type_counter+1) % 3;

                                        if (type_counter === 0 || type_counter === 1)
                                        {
                                            stone_draw_full(stone_get_random_index(), x+stone_width_full*0.5, base_y+stone_height*0.5, w_full, h, false, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                            last_stone_width = total_width_full;
                                        }
                                        else
                                        {
                                            stone_draw_half(stone_get_random_index(), x+stone_width_half*0.5, base_y+stone_height*0.5, w_half, h, false, this.stone_half_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_half_image);

                                            last_stone_width = total_width_half;
                                        }

                                        stone_count++;
                                    }
                                }
                            }
                            else
                            {
                                // Vertical
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_x = offset_x+(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(base_x+(total_height-spacing_height-2), 0, spacing_height+4, canvas_height);
                                    }
                                }

                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_x = offset_x+(row*total_height);
                                    let base_y = canvas_height-offset_y+(row % 2)*((total_width_full+total_width_full+total_width_half)*0.5);

                                    let type_counter = -1;
                                    let last_stone_width;
                                    
                                    for (let y = base_y; y > 0; y -= last_stone_width)
                                    {
                                        type_counter = (type_counter+1) % 3;

                                        if (type_counter === 0 || type_counter === 1)
                                        {
                                            stone_draw_full(stone_get_random_index(), base_x+stone_height*0.5, y-stone_width_full*0.5, w_full, h, true, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                            last_stone_width = total_width_full;
                                        }
                                        else
                                        {
                                            stone_draw_half(stone_get_random_index(), base_x+stone_height*0.5, y-stone_width_half*0.5, w_half, h, true, this.stone_half_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_half_image);

                                            last_stone_width = total_width_half;
                                        }

                                        stone_count++;
                                    }
                                }
                            }

                            break;

                            // ----------------------------------------------------------------

                            // Klezoorverband
                            case 'klezoorverband':
                                    
                            w_full = stone_height*(stone_width_full_mm/stone_height_mm);
                            h = stone_height;
                            
                            if (this.state.configurator_bond_orientation === 0)
                            {
                                // Horizontal
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(0, base_y+(total_height-spacing_height-2), canvas_width, spacing_height+4);
                                    }
                                }

                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_x = offset_x-(row % 2)*total_width_full*0.75;
                                    let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                    for (let x = base_x; x < canvas_width; x += total_width_full)
                                    {
                                        stone_draw_full(stone_get_random_index(), x+stone_width_full*0.5, base_y+stone_height*0.5, w_full, h, false, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                        stone_count++;
                                    }
                                }
                            }
                            else
                            {
                                // Vertical
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_x = offset_x+(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(base_x+(total_height-spacing_height-2), 0, spacing_height+4, canvas_height);
                                    }
                                }

                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_x = offset_x+(row*total_height);
                                    let base_y = canvas_height-offset_y+(row % 2)*total_width_full*0.75;
                                    
                                    for (let y = base_y; y > 0; y -= total_width_full)
                                    {
                                        stone_draw_full(stone_get_random_index(), base_x+stone_height*0.5, y-stone_width_full*0.5, w_full, h, true, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                        stone_count++;
                                    }
                                }
                            }
                            
                            break;

                            // ----------------------------------------------------------------

                            // Kruisverband
                            case 'kruisverband':
                                
                            w_full = stone_height*(stone_width_full_mm/stone_height_mm);
                            w_half = stone_height*(stone_width_half_mm/stone_height_mm);
                            h = stone_height;
                            
                            if (this.state.configurator_bond_orientation === 0)
                            {
                                // Horizontal
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(0, base_y+(total_height-spacing_height-2), canvas_width, spacing_height+4);
                                    }
                                }

                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                    if ((row % 2) === 0)
                                    {
                                        let base_x = offset_x;
                                        for (let x = base_x; x < canvas_width; x += total_width_half)
                                        {
                                            stone_draw_half(stone_get_random_index(), x+stone_width_half*0.5, base_y+stone_height*0.5, w_half, h, false, this.stone_half_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_half_image);

                                            stone_count++;
                                        }
                                    }
                                    else
                                    {
                                        let base_x;
                                        if ((row % 4) === 1)
                                        {base_x = offset_x-total_width_full*0.75;}
                                        else
                                        {base_x = offset_x-total_width_full*0.25;}

                                        for (let x = base_x; x < canvas_width; x += total_width_full)
                                        {
                                            stone_draw_full(stone_get_random_index(), x+stone_width_full*0.5, base_y+stone_height*0.5, w_full, h, false, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                            stone_count++;
                                        }
                                    }
                                }   
                            }
                            else
                            {
                                // Vertical
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_x = offset_x+(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(base_x+(total_height-spacing_height-2), 0, spacing_height+4, canvas_height);
                                    }
                                }

                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_x = offset_x+(row*total_height);
                                    
                                    if ((row % 2) === 0)
                                    {
                                        let base_y = canvas_height-offset_y;

                                        for (let y = base_y; y > 0; y -= total_width_half)
                                        {
                                            stone_draw_half(stone_get_random_index(), base_x+stone_height*0.5, y-stone_width_half*0.5, w_half, h, true, this.stone_half_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_half_image);

                                            stone_count++;
                                        }
                                    }
                                    else
                                    {
                                        let base_y;
                                        if ((row % 4) === 1)
                                        {base_y = canvas_height-offset_y+total_width_full*0.75;}
                                        else
                                        {base_y = canvas_height-offset_y+total_width_full*0.25;}

                                        for (let y = base_y; y > 0; y -= total_width_full)
                                        {
                                            stone_draw_full(stone_get_random_index(), base_x+stone_height*0.5, y-stone_width_full*0.5, w_full, h, true, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                            stone_count++;
                                        }
                                    }
                                }   
                            }

                            break;

                            // ----------------------------------------------------------------

                            // Staand verband
                            case 'staandverband':
                                    
                            w_full = stone_height*(stone_width_full_mm/stone_height_mm);
                            w_half = stone_height*(stone_width_half_mm/stone_height_mm);
                            h = stone_height;
                            
                            if (this.state.configurator_bond_orientation === 0)
                            {
                                // Horizontal
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(0, base_y+(total_height-spacing_height-2), canvas_width, spacing_height+4);
                                    }
                                }

                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                    if ((row % 2) === 0)
                                    {
                                        let base_x = offset_x;
                                        for (let x = base_x; x < canvas_width; x += total_width_half)
                                        {
                                            stone_draw_half(stone_get_random_index(), x+stone_width_half*0.5, base_y+stone_height*0.5, w_half, h, false, this.stone_half_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_half_image);

                                            stone_count++;
                                        }
                                    }
                                    else
                                    {
                                        let base_x = offset_x-total_width_full*0.25;
                                        for (let x = base_x; x < canvas_width; x += total_width_full)
                                        {
                                            stone_draw_full(stone_get_random_index(), x+stone_width_full*0.5, base_y+stone_height*0.5, w_full, h, false, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);
                                            
                                            stone_count++;
                                        }
                                    }
                                }
                            }
                            else
                            {
                                // Vertical
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_x = offset_x+(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(base_x+(total_height-spacing_height-2), 0, spacing_height+4, canvas_height);
                                    }
                                }

                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_x = offset_x+(row*total_height);

                                    if ((row % 2) === 0)
                                    {
                                        let base_y = canvas_height-offset_y;
                                        for (let y = base_y; y > 0; y -= total_width_half)
                                        {
                                            stone_draw_half(stone_get_random_index(), base_x+stone_height*0.5, y-stone_width_half*0.5, w_half, h, true, this.stone_half_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_half_image);

                                            stone_count++;
                                        }
                                    }
                                    else
                                    {
                                        let base_y = canvas_height-offset_y+total_width_full*0.25;
                                        for (let y = base_y; y > 0; y -= total_width_full)
                                        {
                                            stone_draw_full(stone_get_random_index(), base_x+stone_height*0.5, y-stone_width_full*0.5, w_full, h, true, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                            stone_count++;
                                        }
                                    }
                                }
                            }
                            
                            break;

                            // ----------------------------------------------------------------

                            // Stapelverband
                            case 'stapelverband':
                            
                            w_full = stone_height*(stone_width_full_mm/stone_height_mm);
                            h = stone_height;
                            
                            if (this.state.configurator_bond_orientation === 0)
                            {
                                // Horizontal
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(0, base_y+(total_height-spacing_height-2), canvas_width, spacing_height+4);
                                    }
                                }

                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_x = offset_x;
                                    let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                    for (let x = base_x; x < canvas_width; x += total_width_full)
                                    {
                                        stone_draw_full(stone_get_random_index(), x+stone_width_full*0.5, base_y+stone_height*0.5, w_full, h, false, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                        stone_count++;
                                    }
                                }
                            }
                            else
                            {
                                // Vertical
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_x = offset_x+(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(base_x+(total_height-spacing_height-2), 0, spacing_height+4, canvas_height);
                                    }
                                }

                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_x = offset_x+(row*total_height);
                                    let base_y = canvas_height-offset_y;

                                    for (let y = base_y; y > 0; y -= total_width_full)
                                    {
                                        stone_draw_full(stone_get_random_index(), base_x+stone_height*0.5, y-stone_width_full*0.5, w_full, h, true, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                        stone_count++;
                                    }
                                }
                            }
                            
                            break;

                            // ----------------------------------------------------------------
                                    
                            // Wildverband
                            case 'wildverband':
                                
                            w_full = stone_height*(stone_width_full_mm/stone_height_mm);
                            w_half = stone_height*(stone_width_half_mm/stone_height_mm);
                            h = stone_height;
                            
                            if (this.state.configurator_bond_orientation === 0)
                            {
                                // Horizontal
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(0, base_y+(total_height-spacing_height-2), canvas_width, spacing_height+4);
                                    }
                                }

                                let base_x = 0;
                                let base_x_previous1 = 0;
                                let base_x_previous2 = 0;
                                
                                for (let row = 0; row < view_rows; row++)
                                {
                                    while (base_x === base_x_previous1 || base_x === base_x_previous2)
                                    {
                                        base_x = offset_x-Math.floor(Math.random()*8)*(total_width_full*0.125*0.5);
                                    }
                                    base_x_previous2 = base_x_previous1;
                                    base_x_previous1 = base_x;

                                    let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                    let type_random;
                                    let type_full_counter = 0;
                                    let type_half_counter = 0;

                                    let last_stone_width;

                                    for (let x = base_x; x < canvas_width; x += last_stone_width)
                                    {
                                        type_random = Math.round(Math.random());

                                        if (type_random === 0)
                                        {
                                            type_full_counter++;
                                            if (type_full_counter === 3+1)
                                            {
                                                type_full_counter = 0;
                                                type_random = 1;
                                            }
                                        }
                                        else
                                        {
                                            type_half_counter++;
                                            if (type_half_counter === 2+1)
                                            {
                                                type_half_counter = 0;
                                                type_random = 0;
                                            }
                                        }

                                        if (type_random === 0)
                                        {
                                            stone_draw_full(stone_get_random_index(), x+stone_width_full*0.5, base_y+stone_height*0.5, w_full, h, false, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                            last_stone_width = total_width_full;
                                        }
                                        else
                                        {
                                            stone_draw_half(stone_get_random_index(), x+stone_width_half*0.5, base_y+stone_height*0.5, w_half, h, false, this.stone_half_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_half_image);

                                            last_stone_width = total_width_half;
                                        }
                                        
                                        stone_count++;
                                    }
                                }
                            }
                            else
                            {
                                // Vertical
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_x = offset_x+(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(base_x+(total_height-spacing_height-2), 0, spacing_height+4, canvas_height);
                                    }
                                }

                                let base_y = 0;
                                let base_y_previous1 = 0;
                                let base_y_previous2 = 0;

                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_x = offset_x+(row*total_height);

                                    while (base_y === base_y_previous1 || base_y === base_y_previous2)
                                    {
                                        base_y = canvas_height-offset_y+Math.floor(Math.random()*8)*(total_width_full*0.125*0.5);
                                    }
                                    base_y_previous2 = base_y_previous1;
                                    base_y_previous1 = base_y;
                                    
                                    let type_random;
                                    let type_full_counter = 0;
                                    let type_half_counter = 0;

                                    let last_stone_width;

                                    for (let y = base_y; y > 0; y -= last_stone_width)
                                    {
                                        type_random = Math.round(Math.random());

                                        if (type_random === 0)
                                        {
                                            type_full_counter++;
                                            if (type_full_counter === 4+1)
                                            {
                                                type_full_counter = 0;
                                                type_random = 1;
                                            }
                                        }
                                        else
                                        {
                                            type_half_counter++;
                                            if (type_half_counter === 2+1)
                                            {
                                                type_half_counter = 0;
                                                type_random = 0;
                                            }
                                        }

                                        if (type_random === 0)
                                        {
                                            stone_draw_full(stone_get_random_index(), base_x+stone_height*0.5, y-stone_width_full*0.5, w_full, h, true, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                            last_stone_width = total_width_full;
                                        }
                                        else
                                        {
                                            stone_draw_half(stone_get_random_index(), base_x+stone_height*0.5, y-stone_width_half*0.5, w_half, h, true, this.stone_half_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_half_image);

                                            last_stone_width = total_width_half;
                                        }
                                        
                                        stone_count++;
                                    }
                                }
                            }
                            
                            break;

                            // ----------------------------------------------------------------

                            // Vlaams verband
                            case 'vlaamsverband':
                                
                            w_full = stone_height*(stone_width_full_mm/stone_height_mm);
                            w_half = stone_height*(stone_width_half_mm/stone_height_mm);
                            h = stone_height;

                            if (this.state.configurator_bond_orientation === 0)
                            {
                                // Horizontal
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(0, base_y+(total_height-spacing_height-2), canvas_width, spacing_height+4);
                                    }
                                }

                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_x = offset_x-(row % 2)*(total_width_full*0.75);
                                    let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                    let type_counter = -1;
                                    let last_stone_width;

                                    for (let x = base_x; x < canvas_width; x += last_stone_width)
                                    {
                                        type_counter = (type_counter+1) % 2;

                                        if (type_counter === 0)
                                        {
                                            stone_draw_full(stone_get_random_index(), x+stone_width_full*0.5, base_y+stone_height*0.5, w_full, h, false, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                            last_stone_width = total_width_full;
                                        }
                                        else
                                        {
                                            stone_draw_half(stone_get_random_index(), x+stone_width_half*0.5, base_y+stone_height*0.5, w_half, h, false, this.stone_half_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_half_image);

                                            last_stone_width = total_width_half;
                                        }
                                        
                                        stone_count++;
                                    }
                                }
                            }
                            else
                            {
                                // Vertical
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_x = offset_x+(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(base_x+(total_height-spacing_height-2), 0, spacing_height+4, canvas_height);
                                    }
                                }

                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_x = offset_x+(row*total_height);
                                    let base_y = canvas_height-offset_y+((row % 2)*total_width_full*0.75);

                                    let type_counter = -1;
                                    let last_stone_width;

                                    for (let y = base_y; y > 0; y -= last_stone_width)
                                    {
                                        type_counter = (type_counter+1) % 2;

                                        if (type_counter === 0)
                                        {
                                            stone_draw_full(stone_get_random_index(), base_x+stone_height*0.5, y-stone_width_full*0.5, w_full, h, true, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                            last_stone_width = total_width_full;
                                        }
                                        else
                                        {
                                            stone_draw_half(stone_get_random_index(), base_x+stone_height*0.5, y-stone_width_half*0.5, w_half, h, true, this.stone_half_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_half_image);

                                            last_stone_width = total_width_half;
                                        }

                                        stone_count++;
                                    }
                                }
                            }

                            break;

                            // ----------------------------------------------------------------

                            // Engels verband
                            case 'engelsverband':
                                
                            w_full = stone_height*(stone_width_full_mm/stone_height_mm);
                            w_half = stone_height*(stone_width_half_mm/stone_height_mm);
                            h = stone_height;
                            
                            if (this.state.configurator_bond_orientation === 0)
                            {
                                // Horizontal
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(0, base_y+(total_height-spacing_height-2), canvas_width, spacing_height+4);
                                    }
                                }

                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_x = offset_x-(row % 2)*((total_width_full+total_width_full+total_width_half)*0.5);
                                    let base_y = canvas_height-offset_y-total_height-(row*total_height);
                                    
                                    let type_counter = -1;
                                    let last_stone_width;

                                    for (let x = base_x; x < canvas_width; x += last_stone_width)
                                    {
                                        type_counter = (type_counter+1) % 4;

                                        if (type_counter === 0 || type_counter === 1 || type_counter === 2)
                                        {
                                            stone_draw_full(stone_get_random_index(), x+stone_width_full*0.5, base_y+stone_height*0.5, w_full, h, false, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                            last_stone_width = total_width_full;
                                        }
                                        else
                                        {
                                            stone_draw_half(stone_get_random_index(), x+stone_width_half*0.5, base_y+stone_height*0.5, w_half, h, false, this.stone_half_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_half_image);

                                            last_stone_width = total_width_half;
                                        }

                                        stone_count++;
                                    }
                                }
                            }
                            else
                            {
                                // Vertical
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_x = offset_x+(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(base_x+(total_height-spacing_height-2), 0, spacing_height+4, canvas_height);
                                    }
                                }

                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_x = offset_x+(row*total_height);
                                    let base_y = canvas_height-offset_y+(row % 2)*((total_width_full+total_width_full+total_width_half)*0.5);
                                    
                                    let type_counter = -1;
                                    let last_stone_width;
                                    
                                    for (let y = base_y; y > 0; y -= last_stone_width)
                                    {
                                        type_counter = (type_counter+1) % 4;

                                        if (type_counter === 0 || type_counter === 1 || type_counter === 2)
                                        {
                                            stone_draw_full(stone_get_random_index(), base_x+stone_height*0.5, y-stone_width_full*0.5, w_full, h, true, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                            last_stone_width = total_width_full;
                                        }
                                        else
                                        {
                                            stone_draw_half(stone_get_random_index(), base_x+stone_height*0.5, y-stone_width_half*0.5, w_half, h, true, this.stone_half_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_half_image);

                                            last_stone_width = total_width_half;
                                        }

                                        stone_count++;
                                    }
                                }
                            }
                            
                            break;

                            // ----------------------------------------------------------------

                            // Claustra verband
                            case 'claustraverband':
                                
                            w_full = stone_height*(stone_width_full_mm/stone_height_mm);
                            h = stone_height;
                            let factor = 3;
                            
                            if (this.state.configurator_bond_orientation === 0)
                            {
                                // Clear background
                                let clear_row = 1;
                                let clear_base_x = offset_x-((clear_row % 2)*total_width_full*0.5)-((clear_row % 2)*total_width_full/(factor*2))-total_width_full/factor-spacing_width;
                                let clear_overlap = 1; // Pixels overlap left and right to make it look prettier

                                // Draw the spacing lines
                                if (this.state.configurator_spacing_type === 2)
                                {
                                    for (let row = 0; row < view_rows; row++)
                                    {
                                        let base_y = canvas_height-offset_y-total_height-(row*total_height);

                                        context.fillStyle = '#'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
                                        context.fillRect(0, base_y+(total_height-spacing_height-2), canvas_width, spacing_height+4);
                                    }
                                }

                                // Clear the vertical gaps
                                for (let x = clear_base_x; x < canvas_width; x += (total_width_full+total_width_full/factor)*0.5)
                                {
                                    context.fillStyle = '#000000';
                                    context.fillRect(x-clear_overlap, 0, (total_width_full/factor)+spacing_width+clear_overlap*2, canvas_height);
                                }

                                // Horizontal
                                for (let row = 0; row < view_rows; row++)
                                {
                                    let base_x = offset_x-((row % 2)*total_width_full*0.5)-((row % 2)*total_width_full/(factor*2));
                                    let base_y = canvas_height-offset_y-total_height-(row*total_height);
                                    
                                    for (let x = base_x; x < canvas_width; x += total_width_full+total_width_full/factor)
                                    {
                                        stone_draw_full(stone_get_random_index(), x+stone_width_full*0.5, base_y+stone_height*0.5, w_full, h, false, this.stone_full_image, blanco, stone_get_random_index_blanco(), this.stone_blanco_full_image);

                                        stone_count++;
                                    }
                                }
                            }
                            
                            break;

                            default: break;
                        }

                        context.restore();

                        let page_text_count = 0;
                        
                        // Measurement bar area
                        context.clearRect(0, 0, offset_x, canvas_height);
                        context.clearRect(0, canvas_height-offset_y, canvas_width, canvas_height);
                        
                        // Measurement ticks
                        context.lineWidth = 1;
                        context.strokeStyle = '#878b8f';
                        context.font = "24px Arial";
                        context.textAlign = "left";
                        context.fillStyle = "#878b8f";

                        for (let x = 0; x < page_width_mm; x += 500)
                        {
                            let canvas_x = (x/page_width_mm)*(canvas_width-offset_x);

                            context.beginPath();
                            context.moveTo(offset_x+1+canvas_x, canvas_height-offset_y);
                            context.lineTo(offset_x+1+canvas_x, canvas_height-4);
                            context.stroke();
                            
                            context.fillText((x/10)+'cm', offset_x+6+canvas_x, canvas_height-7);
                            page_text_count++;
                        }

                        for (let y = 0; y < page_height_mm; y += 500)
                        {
                            let canvas_y = canvas_height-offset_y-1-(y/page_height_mm)*(canvas_height-offset_y);

                            context.beginPath();
                            context.moveTo(4, canvas_y);
                            context.lineTo(offset_x, canvas_y);
                            context.stroke();

                            page_text_count++;
                        }

                        for (let y = 0; y < page_height_mm; y += 500)
                        {
                            let canvas_y = canvas_height-offset_y-1-(y/page_height_mm)*(canvas_height-offset_y);

                            context.save();
                            context.translate(20, canvas_y-6);
                            context.rotate(-Math.PI*0.5);
                            context.fillText((y/10)+'cm', 0, 0);
                            context.restore();

                            page_text_count++;
                        }

                        // Debug
                        let canvas_render = this.state.debug_canvas_render;
                        this.setState({
                            debug_stone_width_full: stone_width_full,
                            debug_stone_width_half: stone_width_half,
                            debug_stone_height: stone_height,
                            debug_stone_count: stone_count,
                            debug_page_width_mm: page_width_mm,
                            debug_page_height_mm: page_height_mm,
                            debug_page_text_count: page_text_count,
                            debug_canvas_render: canvas_render+1
                        });
                    }
                }
                else
                {
                    // PHOTO --------------------------------------------------------------------------

                    if (this.state.photo_loaded === true)
                    {
                        const context = canvas_id.getContext('2d', {willReadFrequently: true});
                        
                        let offset_x = 0;
                        let offset_y = 0;
                        let width = this.photo_base_image.width;
                        let height = this.photo_base_image.height;

                        let reference_width;
                        let reference_height;

                        if (this.state.photo_size === 0)
                        {
                            // CROPPED VIEW
                            
                            reference_width = Math.max(canvas_width, 1);
                            reference_height = Math.max(canvas_height, 1);

                            // Fit to width
                            width = reference_width;
                            height = width/(this.photo_base_image.width/this.photo_base_image.height);

                            // Fit to height
                            if (height < reference_height)
                            {
                                let factor = reference_height/height;

                                width *= factor;
                                height = reference_height;
                            }

                            offset_x = (reference_width-width)*0.5;
                            offset_y = (reference_height-height)*0.5;
                        }
                        else
                        {
                            // CONTAINED VIEW

                            // Clear
                            context.fillStyle = '#edf1f9';
                            context.fillRect(0, 0, canvas_width, canvas_height);

                            reference_width = Math.max(canvas_width, 1);
                            reference_height = Math.max(canvas_height, 1);

                            // Fit to width
                            width = reference_width;
                            height = width/(this.photo_base_image.width/this.photo_base_image.height);

                            // Fit to height
                            if (height > reference_height)
                            {
                                let factor = reference_height/height;

                                width *= factor;
                                height = reference_height;
                            }

                            offset_x = (reference_width-width)*0.5;
                            offset_y = (reference_height-height)*0.5;
                        }
                        
                        const mixmask_list = [];
                        const mixmask_indices = [];
                        for (let i = 0; i < this.state.content_stone_types.length; i++)
                        {
                            // Check if this stone is in the current collection
                            if (this.state.content_stone_types[i][1] === this.state.content_stone_collections[this.state.configurator_stone_collection][0])
                            {
                                // Check if this stone is selected
                                if (this.state.configurator_stone_multi[i] > 0)
                                {
                                    // Push this index on the mixmask list
                                    for (let j = 0; j < this.state.configurator_stone_multi[i]; j++)
                                    {
                                        mixmask_list.push(i);
                                    }

                                    // Create max index list for this stone layer
                                    mixmask_indices[i] = [];
                                }
                            }
                        }

                        if (mixmask_list.length > 0)
                        {
                            // Add the first 25 indices to the corresponding stone layers
                            let index = 0;
                            for (let i = 0; i < 25; i++)
                            {
                                index = mixmask_list[i % mixmask_list.length];
                                mixmask_indices[index].push(i);
                            }

                            // Create offscreen result canvas
                            let canvas_result = canvas_id.cloneNode();
                            let context_result = canvas_result.getContext('2d', {willReadFrequently: true});
                            canvas_result.width = this.photo_base_image.width;
                            canvas_result.height = this.photo_base_image.height;
                            context_result.globalCompositeOperation = "source-over";

                            // Create offscreen diffuse canvas
                            let canvas_diffuse = canvas_id.cloneNode();
                            let context_diffuse = canvas_diffuse.getContext('2d', {willReadFrequently: true});
                            canvas_diffuse.width = this.photo_base_image.width;
                            canvas_diffuse.height = this.photo_base_image.height;
                            context_diffuse.globalCompositeOperation = "source-over";

                            // Create offscreen mask canvas
                            let canvas_mask = canvas_id.cloneNode();
                            let context_mask = canvas_mask.getContext('2d', {willReadFrequently: true});
                            canvas_mask.width = this.photo_base_image.width;
                            canvas_mask.height = this.photo_base_image.height;
                            
                            // Create offscreen diffuse canvas
                            if (mixmask_list.length === 1)
                            {
                                for (let i = 0; i < this.state.content_stone_types.length; i++)
                                {
                                    // Check if this stone is in the current collection
                                    if (this.state.content_stone_types[i][1] === this.state.content_stone_collections[this.state.configurator_stone_collection][0])
                                    {
                                        if (this.state.configurator_stone_multi[i] > 0)
                                            context_diffuse.drawImage(this.photolayer_diffuse_image[i], 0, 0);
                                    }
                                }

                                // Draw to the result layer
                                context_result.drawImage(canvas_diffuse, 0, 0);
                            }
                            else
                            {
                                // Multi stone: Mixmask
                                for (let i = 0; i < this.state.content_stone_types.length; i++)
                                {
                                    // Check if this stone is in the current collection
                                    if (this.state.content_stone_types[i][1] === this.state.content_stone_collections[this.state.configurator_stone_collection][0])
                                    {
                                        // Check if this stone is selected
                                        if (this.state.configurator_stone_multi[i] > 0)
                                        {
                                            if (mixmask_indices[i].length > 0)
                                            {
                                                // Build the mixmask
                                                context_mask.globalCompositeOperation = "source-over";
                                                context_mask.fillStyle = '#000000';
                                                context_mask.fillRect(0, 0, canvas_mask.width, canvas_mask.height);
                                                
                                                context_mask.globalCompositeOperation = "lighter";
                                                for (let j = 0; j < mixmask_indices[i].length; j++)
                                                {
                                                    context_mask.drawImage(this.photolayer_mixmask_image[mixmask_indices[i][j]], 0, 0);
                                                }

                                                // Draw the diffuse layer
                                                context_diffuse.drawImage(this.photolayer_diffuse_image[i], 0, 0);

                                                // Apply the mask to the diffuse layer
                                                let imagedata_mask = context_mask.getImageData(0, 0, canvas_mask.width, canvas_mask.height);
                                                let data_mask = imagedata_mask.data;
                                                
                                                let imagedata_diffuse = context_diffuse.getImageData(0, 0, canvas_diffuse.width, canvas_diffuse.height);
                                                let data_diffuse = imagedata_diffuse.data;

                                                let k = 0;
                                                while (k < data_diffuse.length)
                                                {
                                                    data_diffuse[k+3] = data_mask[k];

                                                    k += 4;
                                                }

                                                context_diffuse.putImageData(imagedata_diffuse, 0, 0);
                                                
                                                // Draw to the result layer
                                                context_result.drawImage(canvas_diffuse, 0, 0);
                                            }
                                        }
                                    }
                                }
                            }

                            // Exclude 'none' spacing
                            if (this.state.configurator_spacing_type !== 0)
                            {
                                // Set mask canvas for the spacing layer
                                context_mask.globalCompositeOperation = "copy";
                                context_mask.drawImage(this.photolayer_mask_image, 0, 0);

                                // Hex to RGB
                                let bigint = parseInt(this.state.content_spacing_colors[this.state.configurator_spacing_color][0], 16);
                                let r = (bigint >> 16) & 255;
                                let g = (bigint >> 8) & 255;
                                let b = bigint & 255;
                                
                                // Apply spacing mask
                                let imagedata_mask = context_mask.getImageData(0, 0, canvas_mask.width, canvas_mask.height);
                                let data_mask = imagedata_mask.data;
                                
                                let imagedata_result = context_result.getImageData(0, 0, canvas_result.width, canvas_result.height);
                                let data_result = imagedata_result.data;
                                
                                let i = 0;
                                while (i < data_result.length)
                                {
                                    let factor1 = data_mask[i]/255;
                                    let factor2 = 1-factor1;
                                    
                                    data_result[i]   = (data_result[i]   * factor2) + (r * factor1);
                                    data_result[i+1] = (data_result[i+1] * factor2) + (g * factor1);
                                    data_result[i+2] = (data_result[i+2] * factor2) + (b * factor1);
                                    data_result[i+3] = 255;

                                    i += 4;
                                }

                                context_result.putImageData(imagedata_result, 0, 0);
                            }
                            else
                            {
                                // Set mask canvas for the spacing layer
                                context_mask.globalCompositeOperation = "copy";
                            }

                            // Apply lighting
                            context_result.globalCompositeOperation = "multiply";
                            context_result.drawImage(this.photolayer_lighting_image, 0, 0);

                            // Apply dirt
                            context_result.globalCompositeOperation = "multiply";
                            context_result.drawImage(this.photo_dirt_image, 0, 0);
                            
                            // Apply photo mask
                            context_mask.drawImage(this.photo_mask_image, 0, 0);

                            let imagedata_mask = context_mask.getImageData(0, 0, canvas_mask.width, canvas_mask.height);
                            let data_mask = imagedata_mask.data;
                            
                            let imagedata_result = context_result.getImageData(0, 0, canvas_result.width, canvas_result.height);
                            let data_result = imagedata_result.data;
                            
                            let k = 0;
                            while (k < data_result.length)
                            {
                                data_result[k+3] = data_mask[k];

                                k += 4;
                            }

                            context_result.putImageData(imagedata_result, 0, 0)

                            // Draw result
                            context.drawImage(this.photo_base_image, offset_x, offset_y, width, height);
                            context.drawImage(canvas_result, offset_x, offset_y, width, height);
                            
                            // Cleanup
                            canvas_result = null;
                            canvas_diffuse = null;
                            canvas_mask = null;
                        }
                    }
                }
            }
        }
        else
        {
            const context = canvas_id.getContext('2d');
            
            // Clear
            context.fillStyle = '#ffffff';
            context.fillRect(0, 0, canvas_width, canvas_height);
        }
    }

    // -------------------------------------------------------------------

    // Content get intro
    content_get_intro()
    {
        const get_intro = async () => {
            try
            {
                const response = await axios.get(this.ip+'/backend/api/get_content_intro');

                this.setState({
                    content_intro: response.data
                }, () => {
                    if (this.debug === true)
                    {
                        console.log('Content intro');
                        console.log(this.state.content_intro);
                    }

                    this.content_intro_loaded = true;
                    this.startup();
                });
            }

            catch(error)
            {
                console.log(error);
            }
        }

        get_intro();
    }

    // Content get stone collections
    content_get_stone_collections()
    {
        const get_stone_collections = async () => {
            try
            {
                let experimental = '';
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                    experimental = '_experimental';

                const response = await axios.get(this.ip+'/backend/api/get_content_stone_collections'+experimental);

                this.setState({
                    content_stone_collections: response.data
                }, () => {
                    if (this.debug === true)
                    {
                        console.log('Content collections');
                        console.log(this.state.content_stone_collections);
                    }

                    this.content_stone_collections_loaded = true;
                    this.startup();
                });
            }

            catch(error)
            {
                console.log(error);
            }
        }
        
        get_stone_collections();
    }

    // Content get stone types
    content_get_stone_types()
    {
        const get_stone_types = async () => {
            try
            {
                let experimental = '';
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                    experimental = '_experimental';

                const response = await axios.get(this.ip+'/backend/api/get_content_stone_types'+experimental);

                // Create the configurator_stone_multi array according to the stone types
                let multi = [];
                for (let i = 0; i < response.data.length; i++)
                {
                    if (i === 0)
                    {
                        // Set the first one 1
                        multi.push(1);
                    }
                    else
                    {
                        // Set the rest 0
                        multi.push(0);
                    }
                }

                this.setState({
                    content_stone_types: response.data,
                    configurator_stone_multi: multi
                }, () => {
                    if (this.debug === true)
                    {
                        console.log('Content stone types');
                        console.log(this.state.content_stone_types);
                    }

                    this.content_stone_types_loaded = true;
                    this.startup();
                });
            }

            catch(error)
            {
                console.log(error);
            }
        }

        get_stone_types();
    }

    // Content get spacing types
    content_get_spacing_types()
    {
        const get_spacing_types = async () => {
            try
            {
                let experimental = '';
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                    experimental = '_experimental';

                const response = await axios.get(this.ip+'/backend/api/get_content_spacing_types'+experimental);
                
                this.setState({
                    content_spacing_types: response.data
                }, () => {
                    if (this.debug === true)
                    {
                        console.log('Content spacing types');
                        console.log(this.state.content_spacing_types);
                    }

                    this.content_spacing_types_loaded = true;
                    this.startup();
                });
            }

            catch(error)
            {
                console.log(error);
            }
        }
        
        get_spacing_types();
    }

    // Content get spacing colors
    content_get_spacing_colors()
    {
        const get_spacing_colors = async () => {
            try
            {
                let experimental = '';
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                    experimental = '_experimental';

                const response = await axios.get(this.ip+'/backend/api/get_content_spacing_colors'+experimental);

                this.setState({
                    content_spacing_colors: response.data
                }, () => {
                    if (this.debug === true)
                    {
                        console.log('Content spacing colors');
                        console.log(this.state.content_spacing_colors);
                    }

                    this.content_spacing_colors_loaded = true;
                    this.startup();
                });
            }

            catch(error)
            {
                console.log(error);
            }
        }
        
        get_spacing_colors();
    }

    // Content get bond types
    content_get_bond_types()
    {
        const get_bond_types = async () => {
            try
            {
                let experimental = '';
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                    experimental = '_experimental';

                const response = await axios.get(this.ip+'/backend/api/get_content_bond_types'+experimental);

                this.setState({
                    content_bond_types: response.data
                }, () => {
                    if (this.debug === true)
                    {
                        console.log('Content bond types');
                        console.log(this.state.content_bond_types);
                    }

                    this.content_bond_types_loaded = true;
                    this.startup();
                });
            }
            
            catch(error)
            {
                console.log(error);
            }
        }
        
        get_bond_types();
    }

    // Content get bond orientations
    content_get_bond_orientations()
    {
        const get_bond_orientations = async () => {
            try
            {
                let experimental = '';
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                    experimental = '_experimental';

                const response = await axios.get(this.ip+'/backend/api/get_content_bond_orientations'+experimental);

                this.setState({
                    content_bond_orientations: response.data
                }, () => {
                    if (this.debug === true)
                    {
                        console.log('Content bond orientations');
                        console.log(this.state.content_bond_orientations);
                    }

                    this.content_bond_orientations_loaded = true;
                    this.startup();
                });
            }
            
            catch(error)
            {
                console.log(error);
            }
        }
        
        get_bond_orientations();
    }

    // Content get photos
    content_get_photos()
    {
        const get_photos = async () => {
            try
            {
                let experimental = '';
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                    experimental = '_experimental';

                const response = await axios.get(this.ip+'/backend/api/get_content_photos'+experimental);

                this.setState({
                    content_photos: response.data
                }, () => {
                    if (this.debug === true)
                    {
                        console.log('Content photo');
                        console.log(this.state.content_photos);
                    }

                    this.content_photos_loaded = true;
                    this.startup();
                });
            }
            
            catch(error)
            {
                console.log(error);
            }
        }
        
        get_photos();
    }

    // Content get translations
    content_get_translations()
    {
        const get_translations = async() => {
            try
            {
                const response = await axios.get(this.ip+'/backend/api/get_content_translations');
                
                this.setState({
                    content_translations: response.data
                }, () => {
                    if (this.debug === true)
                    {
                        console.log('Content translations');
                        console.log(this.state.content_translations)
                    }

                    // Build translation dictionary
                    for (let i = 0; i < this.state.content_translations.length; i++)
                    {
                        let key = this.state.content_translations[i][0];
                        this.translation_nl[key] = this.state.content_translations[i][1];
                        this.translation_fr[key] = this.state.content_translations[i][2];
                    }
                    
                    this.content_translations_loaded = true;
                    this.startup();
                });
            }
            
            catch(error)
            {
                console.log(error);
            }
        }

        get_translations();
    }

    // Content get form clients
    content_get_form_clients()
    {
        const get_form_clients = async () => {
            try
            {
                const response = await axios.get(this.ip+'/backend/api/get_content_form_clients');

                this.setState({
                    content_form_clients: response.data
                }, () => {
                    if (this.debug === true)
                    {
                        console.log('Content form clients');
                        console.log(this.state.content_form_clients)
                    }

                    this.content_form_clients_loaded = true;
                    this.startup();
                });
            }
            
            catch(error)
            {
                console.log(error);
            }
        }
        
        get_form_clients();
    }
    
    // Content check loaded
    content_check_loaded()
    {
        if (this.download === false)
        {   
            if (this.favourites === false)
            {
                // Normal mode
                if (this.content_intro_loaded === true
                && this.content_stone_collections_loaded === true
                && this.content_stone_types_loaded === true
                && this.content_spacing_types_loaded === true
                && this.content_spacing_colors_loaded === true
                && this.content_bond_types_loaded === true
                && this.content_bond_orientations_loaded === true
                && this.content_photos_loaded === true
                && this.content_translations_loaded === true
                && this.content_form_clients_loaded === true)
                {
                    if (this.debug === true)
                        console.log('Content true');
                    
                    return true;
                }
                else
                {
                    if (this.debug === true)
                        console.log('Content false');

                    return false;
                }
            }
            else
            {
                // Favourites mode
                if (this.content_stone_collections_loaded === true 
                && this.content_stone_types_loaded === true 
                && this.content_spacing_types_loaded === true 
                && this.content_spacing_colors_loaded === true 
                && this.content_bond_types_loaded === true 
                && this.content_bond_orientations_loaded === true 
                && this.content_translations_loaded === true)
                {
                    if (this.debug === true)
                        console.log('Content true');
                    
                    return true;
                }
                else
                {
                    if (this.debug === true)
                        console.log('Content false');
                    
                    return false;
                }
            }
        }
        else
        {
            // Download mode
            return true;
        }
    }

    // -------------------------------------------------------------------
    
    // Startup
    startup()
    {
        if (this.content_check_loaded() === true)
        {
            if (this.state.content_loaded === false)
            {
                this.setState({
                    content_loaded: true
                }, () => {
                    if (this.download === false)
                    {
                        if (this.favourites === false)
                        {
                            // Normal mode

                            // Get query arguments
                            let url_querystring =  window.location.search;
                            
                            if (this.debug === true)
                                console.log(url_querystring);

                            function parseQuery(query)
                            {
                                let object = {};
                                    
                                if (query.indexOf('?') !== -1)
                                {
                                    query = query.split('?');		
                                    query = query[1];
                                }

                                let parse = query.split("&");

                                for (let i = 0; i < parse.length; i++)
                                {
                                    let pair = parse[i].split('=');
                                    let key = decodeURIComponent(pair[0]);
                                    if (key.length === 0) continue;
                                    let value = decodeURIComponent(pair[1].replace("+"," "));

                                    if (object[key] === undefined)
                                        object[key] = value;
                                    else if (object[key] instanceof Array)
                                        object[key].push(value);
                                    else
                                        object[key] = [object[key],value];
                                }

                                return object;
                            };

                            let url_parameters = parseQuery(url_querystring);

                            // URL stone collection
                            let url_stone_collection = null;
                            let url_stone_collection_valid = false;
                            if ('collection' in url_parameters)
                            {
                                for (let i = 0; i < this.state.content_stone_collections.length; i++)
                                {
                                    if (url_parameters['collection'] === this.state.content_stone_collections[i][1])
                                    {
                                        url_stone_collection = i;
                                        url_stone_collection_valid = true;

                                        if (this.debug === true)
                                            console.log('URL stone collection: '+url_parameters['collection']+' '+url_stone_collection);

                                        break;
                                    }
                                }
                            }

                            // URL stones
                            let url_stone_valid = false;
                            let url_stones = [];
                            if (url_stone_collection_valid === true)
                            {
                                for (let i = 0; i < this.state.content_stone_types.length; i++)
                                {
                                    // Check if this stone is in the current collection
                                    if (this.state.content_stone_types[i][1] === this.state.content_stone_collections[url_stone_collection][0])
                                    {
                                        if (this.state.content_stone_types[i][0] in url_parameters)
                                        {
                                            url_stones.push(Number(url_parameters[this.state.content_stone_types[i][0]]));

                                            url_stone_valid = true;

                                            if (this.debug === true)
                                                console.log('URL stone: '+this.state.content_stone_types[i][0]+' '+url_parameters[this.state.content_stone_types[i][0]]+' ');
                                        }
                                        else
                                            url_stones.push(0);
                                    }
                                    else
                                        url_stones.push(0);
                                }
                            }

                            // URL spacing type
                            let url_spacing_type = null;
                            let url_spacing_type_valid = false;
                            if ('spacing' in url_parameters)
                            {
                                for (let i = 0; i < this.state.content_spacing_types.length; i++)
                                {
                                    if (url_parameters['spacing'] === this.state.content_spacing_types[i][0])
                                    {
                                        url_spacing_type = i;
                                        url_spacing_type_valid = true;

                                        if (this.debug === true)
                                            console.log('URL spacing: '+url_spacing_type);

                                        break;
                                    }
                                }
                            }

                            // URL spacing color
                            let url_spacing_color = null;
                            let url_spacing_color_valid = false;
                            if ('color' in url_parameters)
                            {
                                for (let i = 0; i < this.state.content_spacing_colors.length; i++)
                                {
                                    if (url_parameters['color'] === this.state.content_spacing_colors[i][0])
                                    {
                                        url_spacing_color = i;
                                        url_spacing_color_valid = true;

                                        if (this.debug === true)
                                            console.log('URL spacing color: '+url_parameters['color']+' '+url_spacing_color);
                                        
                                        break;
                                    }
                                }
                            }

                            // URL bond type
                            let url_bond_type = null;
                            let url_bond_type_valid = false;
                            if ('bond' in url_parameters)
                            {
                                for (let i = 0; i < this.state.content_bond_types.length; i++)
                                {
                                    if (url_parameters['bond'] === this.state.content_bond_types[i][0])
                                    {
                                        url_bond_type = i;
                                        url_bond_type_valid = true;

                                        if (this.debug === true)
                                            console.log('URL bond type: '+url_parameters['bond']+' '+url_bond_type);

                                        break;
                                    }
                                }
                            }

                            // URL bond orientation
                            let url_bond_orientation = null;
                            let url_bond_orientation_valid = false;
                            if ('orientation' in url_parameters)
                            {
                                for (let i = 0; i < this.state.content_bond_orientations.length; i++)
                                {
                                    if (url_parameters['orientation'] === this.state.content_bond_orientations[i][0])
                                    {
                                        url_bond_orientation = i;
                                        url_bond_orientation_valid = true;

                                        if (this.debug === true)
                                            console.log('URL bond orientation: '+url_parameters['orientation']+' '+url_bond_orientation);

                                        break;
                                    }
                                }
                            }
                            
                            if (url_stone_collection_valid === true && url_stone_valid === true && url_spacing_type_valid === true && url_spacing_color_valid === true && url_bond_type_valid === true && url_bond_orientation_valid === true)
                            {
                                this.setState({
                                    configurator_intro: false,
                                    configurator_stone_collection: url_stone_collection,
                                    configurator_stone_multi: url_stones,
                                    configurator_spacing_type: url_spacing_type,
                                    configurator_spacing_color: url_spacing_color,
                                    configurator_bond_type: url_bond_type,
                                    configurator_bond_orientation: url_bond_orientation,
                                    configurator_changed: true,
                                    autosave_loaded: true // Force autosave loaded to set a starting point
                                }, () => {
                                    // Prepare intro textures
                                    this.intro_prepare_textures();

                                    // Start in stone mode
                                    this.stone_prepare_textures();
                                    this.stone_load_textures();
                                    this.canvas_resize();
                                });
                            }
                            else
                            {
                                // Start in intro mode
                                this.autosave_read_cookie();
                                this.autosave_prepare_textures();
                                this.intro_prepare_textures();
                                this.stone_prepare_textures();
                            }
                        }
                        else
                        {
                            // Favourites mode

                            this.favourites_read_cookies();
                            this.favourites_prepare_textures();
                        }
                    }
                });
            }
        }
    }

    // -------------------------------------------------------------------

    // Intro prepare textures
    intro_prepare_textures()
    {
        if (this.state.content_intro !== null)
        {
            for (let i = 0; i < this.state.content_intro.length; i++)
            {
                let stones = [];

                // stone1 id
                stones.push(this.state.content_intro[i][2]-1);

                // stone2 id
                if (typeof this.state.content_intro[i][3] !== 'object' && this.state.content_intro[i][3] !== null)
                {
                    stones.push(this.state.content_intro[i][3]-1);
                }

                // stone3 id
                if (typeof this.state.content_intro[i][4] !== 'object' && this.state.content_intro[i][4] !== null)
                {
                    stones.push(this.state.content_intro[i][4]-1);
                }
                
                // stone4 id
                if (typeof this.state.content_intro[i][5] !== 'object' && this.state.content_intro[i][5] !== null)
                {   
                    stones.push(this.state.content_intro[i][5]-1);
                }

                // stone5 id
                if (typeof this.state.content_intro[i][6] !== 'object' && this.state.content_intro[i][6] !== null)
                {   
                    stones.push(this.state.content_intro[i][6]-1);
                }

                // stone6 id
                if (typeof this.state.content_intro[i][7] !== 'object' && this.state.content_intro[i][7] !== null)
                {
                    stones.push(this.state.content_intro[i][7]-1);
                }

                // stone7 id
                if (typeof this.state.content_intro[i][8] !== 'object' && this.state.content_intro[i][8] !== null)
                {
                    stones.push(this.state.content_intro[i][8]-1);
                }
                
                // stone8 id
                if (typeof this.state.content_intro[i][9] !== 'object' && this.state.content_intro[i][9] !== null)
                {
                    stones.push(this.state.content_intro[i][9]-1);
                }

                let diffuse_source = [];
                let diffuse_image = [];
                let mixmask_source = [];
                let mixmask_image = [];
                
                for (let j = 0; j < stones.length; j++)
                {
                    diffuse_source.push('/texture/intro_diffuse/'+this.state.content_stone_collections[this.state.content_intro[i][1]-1][1]+'_'+this.state.content_stone_types[stones[j]][0]+'/'+this.state.content_stone_collections[this.state.content_intro[i][1]-1][1]+'_'+this.state.content_stone_types[stones[j]][0]+'_'+this.state.content_spacing_types[this.state.content_intro[i][10]-1][0]+'_'+this.state.content_spacing_colors[this.state.content_intro[i][11]-1][0]+'_'+this.state.content_bond_types[this.state.content_intro[i][12]-1][0]+'_'+this.state.content_bond_orientations[this.state.content_intro[i][13]-1][0]+'_diffuse.jpg');

                    diffuse_image.push(null);

                    if (stones.length > 1)
                    {
                        mixmask_source.push('/texture/intro_mixmask/'+this.state.content_stone_collections[this.state.content_intro[i][1]-1][1]+'_'+this.state.content_spacing_types[this.state.content_intro[i][10]-1][0]+'_'+this.state.content_bond_types[this.state.content_intro[i][12]-1][0]+'_'+this.state.content_bond_orientations[this.state.content_intro[i][13]-1][0]+'_step'+stones.length+'_mixmask'+j+'.jpg');
                        
                        mixmask_image.push(null);
                    }
                }
                
                this.intro_diffuse_source.push(diffuse_source);
                this.intro_diffuse_image.push(diffuse_image);
                this.intro_mixmask_source.push(mixmask_source);
                this.intro_mixmask_image.push(mixmask_image);
                this.intro_stones.push(stones);
            }

            this.intro_load_textures();
        }
        else
        {
            // No intro items; continue
            this.setState({
                intro_loaded: true,
            });
        }
    }

    // Intro load textures
    intro_load_textures()
    {
        let total = 0;

        for (let i = 0; i < this.intro_diffuse_source.length; i++)
        {
            total += this.intro_diffuse_source[i].length;
        }

        for (let i = 0; i < this.intro_mixmask_source.length; i++)
        {
            total += this.intro_mixmask_source[i].length;
        }

        if (total === 0)
        {
            this.intro_callback_counter = 0;
            this.setState({
                intro_loaded_counter: 0,
                intro_loaded_target: 0,
                intro_loaded: true,
            });
        }
        else
        {
            this.intro_callback_counter = 0;
            this.setState({
                intro_loaded_counter: 0,
                intro_loaded_target: total,
                intro_loaded: false
            }, () => {
                for (let i = 0; i < this.intro_diffuse_source.length; i++)
                {
                    for (let j = 0; j < this.intro_diffuse_source[i].length; j++)
                    {
                        let image;

                        image = new Image();
                        image.src = this.intro_diffuse_source[i][j];
                        image.onload = this.intro_callback_loaded_texture;
                        this.intro_diffuse_image[i][j] = image;

                        if (this.debug === true)
                            console.log('Texture '+this.intro_diffuse_source[i][j]);
                    }
                }

                for (let i = 0; i < this.intro_mixmask_source.length; i++)
                {
                    for (let j = 0; j < this.intro_mixmask_source[i].length; j++)
                    {
                        let image;

                        image = new Image();
                        image.src = this.intro_mixmask_source[i][j];
                        image.onload = this.intro_callback_loaded_texture;
                        this.intro_mixmask_image[i][j] = image;

                        if (this.debug === true)
                            console.log('Texture '+this.intro_mixmask_source[i][j]);
                    }
                }
            });
        }
    }

    // Intro callback loaded texture
    intro_callback_loaded_texture()
    {
        this.intro_callback_counter++;

        this.setState({
            intro_loaded_counter: this.intro_callback_counter
        });

        if (this.intro_callback_counter === this.state.intro_loaded_target)
        {
            this.setState({
                intro_loaded: true
            });
        }
    }

    // Intro start existing
    intro_start_existing()
    {
        this.setState({
            configurator_intro_start: true,
        });
    }

    // -------------------------------------------------------------------

    // Stone prepare textures
    stone_prepare_textures()
    {
        for (let i = 0; i < this.state.content_stone_types.length; i++)
        {
            for (let j = 0; j < 7; j++)
            {
                this.stone_full_source.push('/texture/full_diffuse/'+this.state.content_stone_collections[this.state.content_stone_types[i][1]-1][1]+'_'+this.state.content_stone_types[i][0]+'0'+(1+j)+'_full_diffuse.png');
                this.stone_full_image.push(null);

                this.stone_half_source.push('/texture/half_diffuse/'+this.state.content_stone_collections[this.state.content_stone_types[i][1]-1][1]+'_'+this.state.content_stone_types[i][0]+'0'+(1+j)+'_half_diffuse.png');
                this.stone_half_image.push(null);
                
                this.stone_enabled.push(false);
            }
        }

        // Blanco
        for (let i = 0; i < 7; i++)
        {
            // Panorama blanco full
            this.stone_blanco_full_source.push('/texture/full_diffuse/panorama_stone_blanco0'+(1+i)+'_full_diffuse.png');
            this.stone_blanco_full_image.push(null);

            // Panorama blanco half
            this.stone_blanco_half_source.push('/texture/half_diffuse/panorama_stone_blanco0'+(1+i)+'_half_diffuse.png');
            this.stone_blanco_half_image.push(null);
            
            this.stone_blanco_enabled.push(false);
        }
    }

    // Stone load textures
    stone_load_textures()
    {
        // Real textures
        for (let i = 0; i < this.state.content_stone_types.length; i++)
        {
            if (this.state.configurator_stone_multi[i] > 0)
            {
                // Each texture always has 7 variants
                for (let j = 0; j < 7; j++)
                {
                    // Only start loading if the loaded was not started previously
                    let index = (i*7)+j;
                    if (this.stone_enabled[index] === false)
                    {
                        let image;
                        
                        image = new Image();
                        image.src = this.stone_full_source[index];
                        image.onload = this.stone_callback_loaded_texture;
                        this.stone_full_image[index] = image;

                        image = new Image();
                        image.src = this.stone_half_source[index];
                        image.onload = this.stone_callback_loaded_texture;
                        this.stone_half_image[index] = image;

                        this.stone_enabled[index] = true;

                        if (this.debug === true)
                        {
                            console.log('Texture '+this.stone_full_source[index]);
                            console.log('Texture '+this.stone_half_source[index]);
                        }
                    }
                }
            }
        }

        // Blanco textures (blanco is always loaded)
        for (let i = 0; i < 7; i++)
        {
            // Only start loading if the loaded was not started previously
            if (this.stone_blanco_enabled[i] === false)
            {
                let image;
                        
                image = new Image();
                image.src = this.stone_blanco_full_source[i];
                image.onload = this.stone_callback_loaded_texture;
                this.stone_blanco_full_image[i] = image;

                image = new Image();
                image.src = this.stone_blanco_half_source[i];
                image.onload = this.stone_callback_loaded_texture;
                this.stone_blanco_half_image[i] = image;

                this.stone_blanco_enabled[i] = true;

                if (this.debug === true)
                {
                    console.log('Texture '+this.stone_blanco_full_source[i]);
                    console.log('Texture '+this.stone_blanco_half_source[i]);
                }
            }
        }

        // Loading target
        let loaded_target = 0;
        for (let i = 0; i < this.state.content_stone_types.length; i++)
        {
            for (let j = 0; j < 7; j++)
            {
                let index = (i*7)+j;
                if (this.stone_enabled[index] === true)
                    loaded_target += 2; // Count 2 to account for the full and half texture variants
            }
        }
        
        loaded_target += 14; // Account for blanco needing to be loaded now or loaded previously

        this.stone_callback_target = loaded_target;

        // Debug helper
        this.setState({
            content_stone_loaded_counter: this.stone_callback_target
        });

        // Trigger re-render
        this.canvas_render();
    }

    // Stone callback loaded texture
    stone_callback_loaded_texture()
    {
        this.stone_callback_counter++;

        this.setState({
            content_stone_loaded_counter: this.stone_callback_counter
        }, () => this.canvas_render());  
    }

    // Stone check loaded
    stone_check_textures_loaded()
    {
        // Exit out if nothing was loaded yet
        if (this.stone_callback_counter === 0)
            return false;

        if (this.stone_callback_counter === this.stone_callback_target)
        {
            if (this.debug === true)
                console.log('Loaded check: true')
                
            return true;
        }
        else
        {
            if (this.debug === true)
                console.log('Loaded check: false')

            return false;
        }
    }

    // -------------------------------------------------------------------

    // Photo prepare textures
    photo_prepare_textures()
    {
        // Base
        this.photo_base_image = null;
        this.photo_mask_image = null;
        this.photo_dirt_image = null;

        // Photolayer diffuse
        for (let i = 0; i < this.state.content_stone_types.length; i++)
        {
            if (this.photolayer_diffuse_image[i] != null)
            {
                delete this.photolayer_diffuse_image[i];
                this.photolayer_diffuse_image[i] = null;
            }
        }

        // Photolayer lighting
        if (this.photolayer_lighting_image != null)
        {
            delete this.photolayer_lighting_image;
            this.photolayer_lighting_image = null;
        }

        // Photolayer mask
        if (this.photolayer_mask_image != null)
        {
            delete this.photolayer_mask_image;
            this.photolayer_mask_image = null;
        }

        // Photolayer mixmask
        for (let i = 0; i < 25; i++)
        {
            if (this.photolayer_mixmask_image[i] != null)
            {
                delete this.photolayer_mixmask_image[i];
                this.photolayer_mixmask_image[i] = null;
            }
        }

        this.photo_load_textures(true, true, true, true, true);
    }

    // Photo load textures
    photo_load_textures(photo, photolayer_diffuse, photolayer_lighting, photolayer_mask, photolayer_mixmask)
    {
        let total = 0;

        if (photo === true)
        {
            // Delete existing
            if (this.photo_base_image != null)
            {
                delete this.photo_base_image;
                this.photo_base_image = null;
            }

            if (this.photo_mask_image != null)
            {
                delete this.photo_mask_image;
                this.photo_mask_image = null;
            }

            if (this.photo_dirt_image != null)
            {
                delete this.photo_dirt_image;
                this.photo_dirt_image = null;
            }

            total += 3;
        }

        // Excluse Claustra verband
        let claustra = (this.state.content_bond_types[this.state.configurator_bond_type][0] === 'claustraverband');

        if (photolayer_diffuse === true || this.photolayer_lighting_image === null)
        {
            for (let i = 0; i < this.state.content_stone_types.length; i++)
            {
                // Check if this stone is in the current collection
                if (this.state.content_stone_types[i][1] === this.state.content_stone_collections[this.state.configurator_stone_collection][0])
                {
                    // Check if this stone is selected
                    if (this.state.configurator_stone_multi[i] > 0)
                    {
                        // This texture should be added
                        if (this.photolayer_diffuse_image[i] == null && claustra === false)
                        {
                            total++;
                        }
                    }
                    else
                    {
                        // Delete existing
                        if (this.photolayer_diffuse_image[i] != null)
                        {
                            delete this.photolayer_diffuse_image[i];
                            this.photolayer_diffuse_image[i] = null;
                        }
                    }
                }
                else
                {
                    // Delete existing
                    if (this.photolayer_diffuse_image[i] != null)
                    {
                        delete this.photolayer_diffuse_image[i];
                        this.photolayer_diffuse_image[i] = null;
                    }
                }
            }
        }

        if (photolayer_lighting === true || this.photolayer_lighting_image === null)
        {
            // Delete existing
            if (this.photolayer_lighting_image !== null)
            {
                delete this.photolayer_lighting_image;
                this.photolayer_lighting_image = null;
            }

            if (claustra === false)
            {
                total++;
            }
        }

        if (photolayer_mask === true || this.photolayer_mask_image === null)
        {
            // Delete existing
            if (this.photolayer_mask_image !== null)
            {
                delete this.photolayer_mask_image;
                this.photolayer_mask_image = null;
            }
            
            // Exclude 'none' spacing
            if (this.state.configurator_spacing_type !== 0 && claustra === false)
            {
                total++;
            }
        }

        if (photolayer_mixmask === true || this.photolayer_mixmask_image[0] === null)
        {
            // Delete existing
            for (let i = 0; i < 25; i++)
            {
                if (this.photolayer_mixmask_image[i] !== null)
                {
                    delete this.photolayer_mixmask_image[i];
                    this.photolayer_mixmask_image[i] = null;
                }
            }

            if (claustra === false)
                total += 25;
        }

        if (total === 0)
        {
            this.photo_callback_counter = 0;
            this.setState({
                photo_loaded_counter: 0,
                photo_loaded_target: 0,
                photo_loaded: true,
            }, () => this.canvas_render());
        }
        else
        {
            this.photo_callback_counter = 0;
            this.setState({
                photo_loaded_counter: 0,
                photo_loaded_target: total,
                photo_loaded: false
            }, () => {

                if (photo === true)
                {
                    // Base
                    this.photo_base_source = '/texture/photolayer_base/photo'+this.state.content_photos[this.state.photo_current][0]+'_base.jpg';

                    let image = new Image();
                    image.src = this.photo_base_source;
                    image.onload = this.photo_callback_loaded_texture;
                    this.photo_base_image = image;
                    
                    // Mask
                    this.photo_mask_source = '/texture/photolayer_base/photo'+this.state.content_photos[this.state.photo_current][0]+'_mask.jpg';

                    image = new Image();
                    image.src = this.photo_mask_source;
                    image.onload = this.photo_callback_loaded_texture;
                    this.photo_mask_image = image;

                    // Dirt
                    this.photo_dirt_source = '/texture/photolayer_base/photo'+this.state.content_photos[this.state.photo_current][0]+'_dirt.jpg';

                    image = new Image();
                    image.src = this.photo_dirt_source;
                    image.onload = this.photo_callback_loaded_texture;
                    this.photo_dirt_image = image;
                }

                if (photolayer_diffuse === true && claustra === false)
                {
                    for (let i = 0; i < this.state.content_stone_types.length; i++)
                    {
                        // Check if this stone is in the current collection
                        if (this.state.content_stone_types[i][1] === this.state.content_stone_collections[this.state.configurator_stone_collection][0])
                        {
                            // Check if this stone is selected
                            if (this.state.configurator_stone_multi[i] > 0)
                            {
                                // Check of this stone is not enabled already
                                if (this.photolayer_diffuse_image[i] == null)
                                {
                                    this.photolayer_diffuse_source[i] = '/texture/photolayer_diffuse/photo'+this.state.content_photos[this.state.photo_current][0]+'/photo'+this.state.content_photos[this.state.photo_current][0]+'_'+this.state.content_stone_collections[this.state.configurator_stone_collection][1]+'_'+this.state.content_stone_types[i][0]+'_'+this.state.content_spacing_types[this.state.configurator_spacing_type][0]+'_'+this.state.content_bond_types[this.state.configurator_bond_type][0]+'_'+this.state.content_bond_orientations[this.state.configurator_bond_orientation][0]+'_diffuse.jpg';

                                    let image = new Image();
                                    image.src = this.photolayer_diffuse_source[i];
                                    image.onload = this.photo_callback_loaded_texture;
                                    this.photolayer_diffuse_image[i] = image;
                                }
                            }
                        }
                    }
                }

                if (photolayer_lighting === true && claustra === false)
                {
                    this.photolayer_lighting_source = '/texture/photolayer_lighting/photo'+this.state.content_photos[this.state.photo_current][0]+'/photo'+this.state.content_photos[this.state.photo_current][0]+'_'+this.state.content_stone_collections[this.state.configurator_stone_collection][1]+'_stone_cloud_white_'+this.state.content_spacing_types[this.state.configurator_spacing_type][0]+'_'+this.state.content_bond_types[this.state.configurator_bond_type][0]+'_'+this.state.content_bond_orientations[this.state.configurator_bond_orientation][0]+'_lighting.jpg';

                    let image = new Image();
                    image.src = this.photolayer_lighting_source;
                    image.onload = this.photo_callback_loaded_texture;
                    this.photolayer_lighting_image = image;
                }
                
                if (photolayer_mask === true && claustra === false)
                {
                    // Exclude 'none' spacing
                    if (this.state.configurator_spacing_type !== 0)
                    {
                        this.photolayer_mask_source = '/texture/photolayer_mask/photo'+this.state.content_photos[this.state.photo_current][0]+'_'+this.state.content_stone_collections[this.state.configurator_stone_collection][1]+'_'+this.state.content_spacing_types[this.state.configurator_spacing_type][0]+'_'+this.state.content_bond_types[this.state.configurator_bond_type][0]+'_'+this.state.content_bond_orientations[this.state.configurator_bond_orientation][0]+'_mask.jpg';

                        let image = new Image();
                        image.src = this.photolayer_mask_source;
                        image.onload = this.photo_callback_loaded_texture;
                        this.photolayer_mask_image = image;
                    }
                }
                
                if (photolayer_mixmask === true && claustra === false)
                {
                    for (let i = 0; i < 25; i++)
                    {
                        this.photolayer_mixmask_source[i] = '/texture/photolayer_mixmask/photo'+this.state.content_photos[this.state.photo_current][0]+'/photo'+this.state.content_photos[this.state.photo_current][0]+'_'+this.state.content_stone_collections[this.state.configurator_stone_collection][1]+'_'+this.state.content_spacing_types[this.state.configurator_spacing_type][0]+'_'+this.state.content_bond_types[this.state.configurator_bond_type][0]+'_'+this.state.content_bond_orientations[this.state.configurator_bond_orientation][0]+'_mixmask'+i+'.jpg';

                        let image = new Image();
                        image.src = this.photolayer_mixmask_source[i];
                        image.onload = this.photo_callback_loaded_texture;
                        this.photolayer_mixmask_image[i] = image;
                    }
                }
            });
        }
    }

    // Photo callback loaded texture
    photo_callback_loaded_texture()
    {
        this.photo_callback_counter++;

        this.setState({
            photo_loaded_counter: this.photo_callback_counter
        });

        if (this.photo_callback_counter === this.state.photo_loaded_target)
        {
            this.setState({
                photo_loaded: true,
                photo_first: false,
            }, () => this.canvas_render());
        }
    }

    // Photo delete textures
    photo_delete_textures(photo, photolayer_diffuse, photolayer_lighting, photolayer_mask, photolayer_mixmask)
    {
        // Base
        if (photo === true)
        {
            if (this.photo_base_image != null)
            {
                delete this.photo_base_image;
                this.photo_base_image = null;
            }

            if (this.photo_mask_image != null)
            {
                delete this.photo_mask_image;
                this.photo_mask_image = null;
            }

            if (this.photo_dirt_image != null)
            {
                delete this.photo_dirt_image;
                this.photo_dirt_image = null;
            }
        }

        // Photolayer diffuse
        if (photolayer_diffuse === true)
        {
            for (let i = 0; i < this.state.content_stone_types.length; i++)
            {
                if (this.photolayer_diffuse_image[i] != null)
                {
                    delete this.photolayer_diffuse_image[i];
                    this.photolayer_diffuse_image[i] = null;
                }
            }
        }

        // Photolayer lighting
        if (photolayer_lighting === true)
        {
            if (this.photolayer_lighting_image != null)
            {
                delete this.photolayer_lighting_image;
                this.photolayer_lighting_image = null;
            }
        }

        // Photolayer mask
        if (photolayer_mask === true)
        {
            if (this.photolayer_mask_image != null)
            {
                delete this.photolayer_mask_image;
                this.photolayer_mask_image = null;
            }
        }

        // Photolayer mixmask
        if (photolayer_mixmask === true)
        {
            for (let i = 0; i < 25; i++)
            {
                if (this.photolayer_mixmask_image[i] != null)
                {
                    delete this.photolayer_mixmask_image[i];
                    this.photolayer_mixmask_image[i] = null;
                }
            }
        }
    }

    // -------------------------------------------------------------------

    // Configurator start
    configurator_start(intro_index)
    {
        // Clear
        let multi = [];
        for (let i = 0; i < this.state.content_stone_types.length; i++)
        {
            multi.push(0);
        }

        // Set
        for (let i = 0; i < this.intro_stones[intro_index].length; i++)
        {
            multi[this.intro_stones[intro_index][i]]++;
        }

        this.setState({
            configurator_intro: false,
            configurator_stone_collection: this.state.content_intro[intro_index][1]-1,
            configurator_stone_multi: multi,
            configurator_spacing_type: this.state.content_intro[intro_index][10]-1,
            configurator_spacing_color: this.state.content_intro[intro_index][11]-1,
            configurator_bond_type: this.state.content_intro[intro_index][12]-1,
            configurator_bond_orientation: this.state.content_intro[intro_index][13]-1
        }, () => {
            this.stone_load_textures();
            this.canvas_resize();
        });
    }

    // CoOnfigurator start blanco
    configurator_start_blanco()
    {
        let multi = [];
        for (let i = 0; i < this.state.content_stone_types.length; i++)
        {
            // Check if this stone is in the current collection
            if (this.state.content_stone_types[i][1] === this.state.content_stone_collections[0][0])
            {
                multi.push(0);
            }
        }

        this.setState({
            configurator_intro: false,
            configurator_stone_collection: 0,
            configurator_stone_multi: multi,
            configurator_spacing_type: 1,
            configurator_spacing_color: 0,
            configurator_bond_type: 0,
            configurator_bond_orientation: 0
        }, () => {
            this.stone_load_textures();
            this.canvas_resize();
        });
    }

    // Configurator start autosave
    configurator_start_autosave()
    {
        this.setState({
            configurator_intro: false,
            configurator_stone_collection: this.autosave_stone_collection,
            configurator_stone_multi: this.autosave_stone,
            configurator_spacing_type: this.autosave_spacing_type,
            configurator_spacing_color: this.autosave_spacing_color,
            configurator_bond_type: this.autosave_bond_type,
            configurator_bond_orientation: this.autosave_bond_orientation
        }, () => {
            this.stone_load_textures();
            this.canvas_resize();
        });
    }

    // Configurator start continue
    configurator_start_continue()
    {
        this.setState({
            configurator_intro: false
        }, () => this.canvas_resize());
    }

    // Configurator stone collection
    configurator_stone_collection(index)
    {
        if (this.state.content_loaded === true)
        {
            if (this.state.view_mode === 0 || (this.state.view_mode === 1 && this.state.photo_loaded === true))
            {
                if (this.state.view_mode === 0)
                {
                    this.setState({
                        configurator_stone_collection: index
                    }, () => {
                        this.autosave_save_cookie();
                        this.message_post_querystring(false, false, false);
                        this.stone_load_textures();
                    });
                }

                if (this.state.view_mode === 1 && this.state.photo_loaded === true)
                {
                    this.setState({
                        configurator_stone_collection: index
                    }, () => {
                        this.autosave_save_cookie();
                        this.message_post_querystring(false, false, false);
                        this.photo_load_textures(false, true, true, true, true);
                    });
                }
            }
        }
    }

    // Configurator stonetype multi increase
    configurator_stone_select_multi_increase(index)
    {
        if (this.state.content_loaded === true)
        {
            if (this.state.view_mode === 0 || (this.state.view_mode === 1 && this.state.photo_loaded === true))
            {
                let multi = this.state.configurator_stone_multi;
                multi[index] += 1;
                if (multi[index] > 100)
                {
                    multi[index] = 100;
                }

                if (this.state.view_mode === 0)
                {
                    this.setState({
                        configurator_stone_multi: multi
                    }, () => {
                        this.autosave_save_cookie();
                        this.message_post_querystring(false, false, false);
                        this.stone_load_textures();
                        this.message_post_height();
                    });
                }

                if (this.state.view_mode === 1 && this.state.photo_loaded === true)
                {
                    this.setState({
                        configurator_stone_multi: multi
                    }, () => {
                        this.autosave_save_cookie();
                        this.message_post_querystring(false, false, false);
                        this.photo_load_textures(false, true, false, false, false);
                        this.message_post_height();
                    });
                }
            }
        }
    }

    // Configurator stonetype multi decrease
    configurator_stone_select_multi_decrease(index)
    {
        if (this.state.content_loaded === true)
        {
            if (this.state.view_mode === 0 || (this.state.view_mode === 1 && this.state.photo_loaded === true))
            {
                let multi = this.state.configurator_stone_multi;
                multi[index] -= 1;
                if (multi[index] < 0)
                {
                    multi[index] = 0;
                }

                if (this.state.view_mode === 0)
                {
                    this.setState({
                        configurator_stone_multi: multi
                    }, () => {
                        this.autosave_save_cookie();
                        this.message_post_querystring(false, false, false);
                        this.message_post_height();
                        this.canvas_render();
                    });
                }

                if (this.state.view_mode === 1 && this.state.photo_loaded === true)
                {
                    this.setState({
                        configurator_stone_multi: multi
                    }, () => {
                        this.autosave_save_cookie();
                        this.message_post_querystring(false, false, false);
                        this.photo_load_textures(false, true, false, false, false);
                        this.message_post_height();
                    });
                }
            }
        }
    }

    // Configurator spacing type
    configurator_spacing_type(index)
    {
        if (this.state.content_loaded === true)
        {
            if (this.state.view_mode === 0)
            {
                this.setState({
                    configurator_spacing_type: index
                }, () => {
                    this.autosave_save_cookie();
                    this.message_post_querystring(false, false, false);
                    this.canvas_render();
                });
            }

            if (this.state.view_mode === 1 && this.state.photo_loaded === true)
            {
                this.setState({
                    configurator_spacing_type: index
                }, () => {
                    this.autosave_save_cookie();
                    this.message_post_querystring(false, false, false);
                    this.photo_delete_textures(false, true, false, false);
                    this.photo_load_textures(false, true, true, true, true);
                });
            }
        }
    }

    // Configurator spacing color
    configurator_spacing_color(index)
    {
        if (this.state.content_loaded === true)
        {
            if (this.state.view_mode === 0 || (this.state.view_mode === 1 && this.state.photo_loaded === true))
            {
                this.setState({
                    configurator_spacing_color: index
                }, () => {
                    this.autosave_save_cookie();
                    this.message_post_querystring(false, false, false);
                    this.canvas_render();
                });
            }
        }
    }

    // Configurator bond type
    configurator_bond_type(index)
    {
        if (this.state.content_loaded === true)
        {
            if (this.state.view_mode === 0)
            {
                this.setState({
                    configurator_bond_type: index
                }, () => {
                    this.autosave_save_cookie();
                    this.message_post_querystring(false, false, false);
                    this.canvas_render();
                });
            }

            if (this.state.view_mode === 1 && this.state.photo_loaded === true)
            {
                this.setState({
                    configurator_bond_type: index
                }, () => {
                    this.autosave_save_cookie();
                    this.message_post_querystring(false, false, false);
                    this.photo_delete_textures(false, true, false, false);
                    this.photo_load_textures(false, true, true, true, true);
                });
            }
        }
    }

    // Configurator bond orientation
    configurator_bond_orientation(index)
    {
        if (this.state.content_loaded === true)
        {
            if (this.state.view_mode === 0)
            {
                this.setState({
                    configurator_bond_orientation: index
                }, () => {
                    this.autosave_save_cookie();
                    this.message_post_querystring(false, false, false);
                    this.canvas_render();
                });
            }

            if (this.state.view_mode === 1 && this.state.photo_loaded === true)
            {
                this.setState({
                    configurator_bond_orientation: index
                }, () => {
                    this.autosave_save_cookie();
                    this.message_post_querystring(false, false, false);
                    this.photo_delete_textures(false, true, false, false);
                    this.photo_load_textures(false, true, true, true, true);
                });
            }
        }
    }

    // Configurator enable mobile
    configurator_enable_mobile(index)
    {
        this.setState({
            configurator_mobile: index
        });

        if (this.debug === true)
            console.log('Mobile enabled: '+index);
    }

    // Configurator disable mobile
    configurator_disable_mobile()
    {
        this.setState({
            configurator_mobile: null
        });

        if (this.debug === true)
            console.log('Mobile disabled');
    }

    // Configurator adapt dropdowns
    configurator_adapt_dropdowns()
    {
        function dropdown_adaptive(id, view_mode, window_height)
        {
            let element = document.getElementById(id);
            if (element !== null)
            {
                let rectangle = element.getBoundingClientRect();

                // Prevent the dropdown menu from going offscreen
                element.nextElementSibling.style.left = "0px";
                if (rectangle.x+304 > window.innerWidth)
                {
                    element.nextElementSibling.style.left = ((rectangle.x-(window.innerWidth-304))*-1)+"px";
                }

                element.nextElementSibling.style.maxHeight = (window_height-rectangle.y-88)+"px";
            }
        }
        
        dropdown_adaptive('button-stone-collection', this.state.view_mode, this.state.window_height);
        dropdown_adaptive('button-stone-type', this.state.view_mode, this.state.window_height);
        dropdown_adaptive('button-spacing-type', this.state.view_mode, this.state.window_height);
        dropdown_adaptive('button-spacing-color', this.state.view_mode, this.state.window_height);
        dropdown_adaptive('button-bond-type', this.state.view_mode, this.state.window_height);
        dropdown_adaptive('button-bond-orientation', this.state.view_mode, this.state.window_height);
    }

    // Configurator check valid
    configurator_check_valid()
    {
        let condition_intro = (this.state.configurator_intro === true);

        let condition_claustra_vertical = false;
        let condition_claustra_photo = false;

        if (this.stone_check_textures_loaded() === true)
        {
            condition_claustra_vertical = (this.state.configurator_intro === false && this.state.content_bond_types[this.state.configurator_bond_type][0] === 'claustraverband' && this.state.configurator_bond_orientation === 1);

            condition_claustra_photo = (this.state.configurator_intro === false && this.state.content_bond_types[this.state.configurator_bond_type][0] === 'claustraverband' && this.state.view_mode === 1);

            if (condition_intro === false && condition_claustra_vertical === false && condition_claustra_photo === false)
            {
                if (this.debug === true)
                    console.log('Valid check: true')

                return true;
            }
            else
            {
                if (this.debug === true)
                    console.log('Valid check: false (checks)')

                return false;
            }
        }
        else
        {
            if (this.debug === true)
                console.log('Valid check: false')

            return false;
        }
    }

    // Configurator get querystring
    configurator_get_querystring()
    {
        let querystring = '';
        
        querystring += 'collection='+this.state.content_stone_collections[this.state.configurator_stone_collection][1];

        for (let i = 0; i < this.state.content_stone_types.length; i++)
        {
            // Check if this stone is in the current collection
            if (this.state.content_stone_types[i][1] === this.state.content_stone_collections[this.state.configurator_stone_collection][0])
            {
                if (this.state.configurator_stone_multi[i] > 0)
                {
                    querystring += '&'+this.state.content_stone_types[i][0]+'='+this.state.configurator_stone_multi[i];
                }
            }
        }

        querystring += '&spacing='+this.state.content_spacing_types[this.state.configurator_spacing_type][0];

        if (this.state.configurator_spacing_type !== 0)
        {
            // Do not save color for spacing=none
            querystring += '&color='+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
        }
        else
        {
            // Set a default color
            querystring += '&color=f0f0f0';
        }

        querystring += '&bond='+this.state.content_bond_types[this.state.configurator_bond_type][0];
        querystring += '&orientation='+this.state.content_bond_orientations[this.state.configurator_bond_orientation][0];
        return querystring;
    }

    // -------------------------------------------------------------------

    // Cookie read
    cookie_read(cookie)
    {
        // Split cookie into arguments
        let parse_split = cookie.split('&');
        
        let parse_stone_collection = null;
        let parse_stones = null;
        let parse_spacing_type = null;
        let parse_spacing_color = null;
        let parse_bond_type = null;
        let parse_bond_orientation = null;
        let parse_date = null;

        let stones = [];
        for (let i = 0; i < this.state.content_stone_types.length; i++)
        {
            stones.push(0);
        }

        for (let i = 0; i < parse_split.length; i++)
        {
            // Check collection
            if (parse_split[i].includes('collection='))
            {   
                let str = parse_split[i].replace('collection=', '');
                
                for (let j = 0; j < this.state.content_stone_collections.length; j++)
                {
                    if (str === this.state.content_stone_collections[j][1])
                    {
                        parse_stone_collection = j;

                        if (this.debug === true)
                            console.log('Cookie collection: '+parse_stone_collection);
                    }
                }
            }

            // Check stones
            for (let j = 0; j < this.state.content_stone_types.length; j++)
            {
                if (parse_split[i].includes(this.state.content_stone_types[j][0]+'='))
                {
                    let value = parse_split[i].replace(this.state.content_stone_types[j][0]+'=', '');

                    stones[j] = parseInt(value);
                }
            }
            
            // Check spacing type
            if (parse_split[i].includes('spacing='))
            {
                let str = parse_split[i].replace('spacing=', '');

                for (let j = 0; j < this.state.content_spacing_types.length; j++)
                {
                    if (str === this.state.content_spacing_types[j][0])
                    {
                        parse_spacing_type = j;

                        // Set spacing color to default for spacing=none. This value is not saved when writing the cookie
                        if (j === 0)
                        {
                            parse_spacing_color = 0;
                        }

                        if (this.debug === true)
                            console.log('Cookie spacing type: '+parse_spacing_type);
                    }
                }
            }

            // Check spacing color
            if (parse_split[i].includes('color='))
            {
                let str = parse_split[i].replace('color=', '');

                for (let j = 0; j < this.state.content_spacing_colors.length; j++)
                {
                    if (str === this.state.content_spacing_colors[j][0])
                    {
                        parse_spacing_color = j;

                        if (this.debug === true)
                            console.log('Cookie spacing color: '+parse_spacing_color);
                    }
                }
            }

            // Check bond type
            if (parse_split[i].includes('bond='))
            {
                let str = parse_split[i].replace('bond=', '');

                for (let j = 0; j < this.state.content_bond_types.length; j++)
                {
                    if (str === this.state.content_bond_types[j][0])
                    {
                        parse_bond_type = j;

                        if (this.debug === true)
                            console.log('Cookie bond type: '+parse_bond_type);
                    }
                }
            }

            // Check bond orientation
            if (parse_split[i].includes('orientation='))
            {
                let str = parse_split[i].replace('orientation=', '');

                for (let j = 0; j < this.state.content_bond_orientations.length; j++)
                {
                    if (str === this.state.content_bond_orientations[j][0])
                    {
                        parse_bond_orientation = j;

                        if (this.debug === true)
                            console.log('Cookie bond orientation: '+parse_bond_orientation);
                    }
                }
            }

            // Check date
            if (parse_split[i].includes('date='))
            {
                let str = parse_split[i].replace('date=', '');
                parse_date = str;

                if (this.debug === true)
                    console.log('Cookie date: '+parse_date);
            }
        }

        // Check stone
        let total = 0;
        for (let i = 0; i < this.state.content_stone_types.length; i++)
        {
            total += stones[i];
        }
        if (total > 0)
            parse_stones = stones;

        // Validate
        if (parse_stone_collection !== null
        && parse_stones !== null
        && parse_spacing_type !== null
        && parse_spacing_color !== null
        && parse_bond_type !== null
        && parse_bond_orientation !== null
        && parse_date !== null)
        {
            this.cookie_stone_collection = parse_stone_collection;
            this.cookie_stone = parse_stones;
            this.cookie_spacing_type = parse_spacing_type;
            this.cookie_spacing_color = parse_spacing_color;
            this.cookie_bond_type = parse_bond_type;
            this.cookie_bond_orientation = parse_bond_orientation;
            this.cookie_date = parse_date;

            return true;
        }

        return false;
    }

    // -------------------------------------------------------------------

    // Autosave save cookie
    autosave_save_cookie()
    {
        let total = 0;
        for (let i = 0; i < this.state.content_stone_types.length; i++)
        {
            // Check if this stone is in the current collection
            if (this.state.content_stone_types[i][1] === this.state.content_stone_collections[this.state.configurator_stone_collection][0])
            {
                // Check if this stone is selected
                if (this.state.configurator_stone_multi[i] > 0)
                    total++;
            }
        }

        if (total > 0)
        {
            // Save cookie
            let cookie = this.configurator_get_querystring();

            const date = new Date();
            cookie += '&date='+date.getDate()+'-'+(date.getMonth()+1)+'-'+date.getFullYear();

            let domain;
            domain = this.ip;
            domain = domain.replace('https://', '');

            Cookies.set('texgen_autosave', cookie, {domain: domain, expires: 180});
        }

        // Track change
        this.setState({
            configurator_changed: true
        });
    }

    // Autosave read cookie
    autosave_read_cookie()
    {
        let cookies = {};

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
        {
            cookies = {
                'texgen_autosave': 'collection=panorama&stone_cloud_white=1&stone_dim_grey=1&spacing=none&color=f0f0f0&bond=wildverband&orientation=h&date=26-1-2023'
            }
        }
        else
            cookies = Cookies.get();

        let cookies_enumerate = Object.entries(cookies);
        for (let i = 0; i < cookies_enumerate.length; i++)
        {
            if (cookies_enumerate[i][0].includes('autosave', 0) === true)
            {
                if (this.cookie_read(cookies_enumerate[i][1]) === true)
                {
                    this.autosave_found = true;
                    this.autosave_stone_collection = this.cookie_stone_collection;
                    this.autosave_stone = this.cookie_stone;
                    this.autosave_spacing_type = this.cookie_spacing_type;
                    this.autosave_spacing_color = this.cookie_spacing_color;
                    this.autosave_bond_type = this.cookie_bond_type;
                    this.autosave_bond_orientation = this.cookie_bond_orientation;
                    this.autosave_date = this.cookie_date;

                    if (this.debug === true)
                        console.log('Autosave '+cookies_enumerate[i][1]);
                    
                    return true;
                }
            }
        }

        return false;
    }

    // Autosave prepare textures
    autosave_prepare_textures()
    {
        if (this.autosave_found === true)
        {
            let stones_indices = [];
            for (let i = 0; i < this.autosave_stone.length; i++)
            {
                for (let j = 0; j < this.autosave_stone[i]; j++)
                {
                    stones_indices.push(i);
                }
            }

            let stones = [];
            if (stones_indices.length <= 8)
            {
                for (let i = 0; i < stones_indices.length; i++)
                {
                    stones.push(stones_indices[i]);
                }
            }
            else
            {
                for (let i = 0; i < 8; i++)
                {
                    let random_index = Math.floor(Math.random()*stones_indices.length);

                    stones.push(stones_indices[random_index]);
                }
            }

            let diffuse_source = [];
            let diffuse_image = [];
            let mixmask_source = [];
            let mixmask_image = [];

            for (let i = 0; i < stones.length; i++)
            {
                diffuse_source.push('/texture/intro_diffuse/'+this.state.content_stone_collections[this.autosave_stone_collection][1]+'_'+this.state.content_stone_types[stones[i]][0]+'/'+this.state.content_stone_collections[this.autosave_stone_collection][1]+'_'+this.state.content_stone_types[stones[i]][0]+'_'+this.state.content_spacing_types[this.autosave_spacing_type][0]+'_'+this.state.content_spacing_colors[this.autosave_spacing_color][0]+'_'+this.state.content_bond_types[this.autosave_bond_type][0]+'_'+this.state.content_bond_orientations[this.autosave_bond_orientation][0]+'_diffuse.jpg');

                diffuse_image.push(null);

                if (stones.length > 1)
                {
                    mixmask_source.push('/texture/intro_mixmask/'+this.state.content_stone_collections[this.autosave_stone_collection][1]+'_'+this.state.content_spacing_types[this.autosave_spacing_type][0]+'_'+ this.state.content_bond_types[this.autosave_bond_type][0]+'_'+this.state.content_bond_orientations[this.autosave_bond_orientation][0]+'_step'+stones.length+'_mixmask'+i+'.jpg');

                    mixmask_image.push(null);
                }
            }

            this.autosave_diffuse_source = diffuse_source;
            this.autosave_diffuse_image = diffuse_image;
            this.autosave_mixmask_source = mixmask_source;
            this.autosave_mixmask_image = mixmask_image;
            this.autosave_stones = stones;

            this.autosave_load_textures();
        }
        else
        {
            // No autosave; continue
            this.setState({
                autosave_loaded: true
            })
        }
    }

    // Autosave load textures
    autosave_load_textures()
    {
        let total = 0;

        total += this.autosave_diffuse_source.length;
        total += this.autosave_mixmask_source.length;

        if (total === 0)
        {
            this.autosave_callback_counter = 0;
            this.setState({
                autosave_loaded_counter: 0,
                autosave_loaded_target: 0,
                autosave_loaded: true,
            });
        }
        else
        {
            this.autosave_callback_counter = 0;
            this.setState({
                autosave_loaded_counter: 0,
                autosave_loaded_target: total,
                autosave_loaded: false
            }, () => {
                for (let i = 0; i < this.autosave_diffuse_source.length; i++)
                {
                    let image;

                    image = new Image();
                    image.src = this.autosave_diffuse_source[i];
                    image.onload = this.autosave_callback_loaded_texture;
                    this.autosave_diffuse_image[i] = image;

                    if (this.debug === true)
                        console.log('Texture '+this.autosave_diffuse_source[i]);
                }

                for (let i = 0; i < this.autosave_mixmask_source.length; i++)
                {
                    let image;

                    image = new Image();
                    image.src = this.autosave_mixmask_source[i];
                    image.onload = this.autosave_callback_loaded_texture;
                    this.autosave_mixmask_image[i] = image;

                    if (this.debug === true)
                        console.log('Texture '+this.autosave_mixmask_source[i]);
                }
            });
        }
    }

    // Autosave callback loaded texture
    autosave_callback_loaded_texture()
    {
        this.autosave_callback_counter++;

        this.setState({
            autosave_loaded_counter: this.autosave_callback_counter
        });

        if (this.autosave_callback_counter === this.state.autosave_loaded_target)
        {
            this.setState({
                autosave_loaded: true
            }, () => this.canvas_render());
        }
    }

    // -------------------------------------------------------------------

    // View mode
    view_mode(index)
    {
        if (index === 0 && this.state.photo_loaded === true)
        {
            this.setState({
                view_mode: 0,
                photo_first: true,
            }, () => {
                this.canvas_resize();
                this.photo_delete_textures(true, true, true, true);
                this.stone_load_textures();
                this.message_post_height();
            });
        }

        if (index === 1)
        {
            if (this.state.photo_current == null)
            {
                // Set the first photo when changing to photo mode for the first time
                this.setState({
                    view_mode: 1,
                    photo_current: 0
                }, () => {
                    this.canvas_resize();
                    this.photo_prepare_textures();
                    this.message_post_height();
                });
            }
            else
            {
                this.setState({
                    view_mode: 1
                }, () => {
                    this.canvas_resize();
                    this.photo_prepare_textures();
                    this.message_post_height();
                });
            }
        }
    }

    // View rows zoom in
    view_rows_zoomin()
    {
        if (this.state.view_rows > this.state.view_rows_min)
        {
            let rows = this.state.view_rows-this.state.view_rows_increment;
            
            this.setState({
                view_rows: rows
            }, () => this.canvas_render());
        }
    }

    // View rows zoom out
    view_rows_zoomout()
    {
        if (this.state.view_rows < this.state.view_rows_max)
        {
            let rows = this.state.view_rows+this.state.view_rows_increment;

            this.setState({
                view_rows: rows
            }, () => this.canvas_render());
        }
    }

    // View back to intro items
    view_back_to_intro_items()
    {
        if (this.state.view_mode === 0)
        {
            this.setState({
                configurator_intro: true,
                configurator_intro_start: true,
                photo_first: true,
            });
        }
        else
        {
            this.setState({
                configurator_intro: true,
                configurator_intro_start: true,
                photo_first: true,
                view_mode: 0,
            }, () => {
                this.photo_delete_textures(true, true, true, true);
                this.stone_load_textures();
            });
        }
    }

    // -------------------------------------------------------------------

    // Photo select
    photo_select(index)
    {
        if (this.state.photo_loaded === true)
        {
            this.setState({
                photo_current: index
            }, () => {
                this.photo_delete_textures(false, true, false, false);
                this.photo_load_textures(true, true, true, true, true);
            });
        }
    }

    // Photo previous
    photo_previous()
    {
        if (this.state.photo_loaded === true)
        {
            let photo = this.state.photo_current-1;
            if (photo < 0)
            {
                photo = this.state.content_photos.length-1;
            }

            this.setState({
                photo_current: photo
            }, () => {
                this.photo_delete_textures(false, true, false, false);
                this.photo_load_textures(true, true, true, true, true);
            });
        }
    }

    // Photo next
    photo_next()
    {
        if (this.state.photo_loaded === true)
        {
            let photo = this.state.photo_current+1;
            if (photo >= this.state.content_photos.length)
            {
                photo = 0;
            }

            this.setState({
                photo_current: photo
            }, () => {
                this.photo_delete_textures(false, true, false, false);
                this.photo_load_textures(true, true, true, true, true);
            });
        }
    }

    // Photo size
    photo_size(index)
    {
        if (this.state.photo_loaded === true)
        {
            this.setState({
                photo_size: index
            }, () => this.canvas_render());
        }
    }

    // -------------------------------------------------------------------

    // Popup open
    popup_open(str)
    {
        this.setState({
            popup_current: str
        });
    }

    // Popup close
    popup_close()
    {
        this.setState({
            popup_current: null
        });
    }

    // -------------------------------------------------------------------

    // Message incoming
    message_incoming(event)
    {
        switch (event.data.type)
        {
            case 'window_height':   this.setState({
                                        window_height: event.data.height
                                    }, () => {
                                        this.forceUpdate();
                                        this.message_post_height();
                                    });
            
                                    break;

            default:                break;
        }
    }

    // Message post request window height
    message_post_request_window_height()
    {
        // Check if parent exists
        if (window.top !== window.self)
        {
            const message = {
                type: 'request_window_height'
            };
            window.parent.postMessage(message, '*');
        }
    }

    // Message post height
    message_post_height()
    {
        // Check if parent exists
        if (window.top !== window.self)
        {
            // Pre-calc the footer (if it exists)
            let elements = document.getElementsByClassName('body-footer');
            if (elements.length !== 0)
            {
                let rectangle = elements[0].getBoundingClientRect();
                this.message_footer_height = rectangle.height;
            }
            else
            {
                this.message_footer_height = 0;
            }

            // Send app height
            let element = document.getElementById('app');
            if (element !== null)
            {
                let rectangle = element.getBoundingClientRect();

                const message = {
                    type: 'app_height',
                    height: rectangle.height+"px"
                };
                window.parent.postMessage(message, '*');
            }
        }
    }

    // Message post querystring
    message_post_querystring(download_pdf_2d, download_pdf_photo, download_textures)
    {
        // Check if parent exists
        if (window.top !== window.self)
        {
            let querystring = this.configurator_get_querystring();

            if (download_pdf_2d === true)
                querystring += '&download=pdf_2d';

            if (download_pdf_photo === true)
                querystring += '&download=pdf_photo';

            if (download_textures === true)
                querystring += '&download=textures';

            const message = {
                type: 'filter_update',
                params: querystring
            };
            window.parent.postMessage(message, '*');
        }
    }

    // Message post favourite
    message_post_favourite()
    {
        // Check if parent exists
        if (window.top !== window.self)
        {
            const message = {
                type: 'favorite'
            };
            window.parent.postMessage(message, '*');
        }
    }

    // -------------------------------------------------------------------

    // Favourites save cookie
    favourites_save_cookie()
    {
        let cookie = this.configurator_get_querystring();

        const date = new Date();
        cookie += '&date='+date.getDate()+'-'+(date.getMonth()+1)+'-'+date.getFullYear();

        let counter = 0;
        while (Cookies.get('texgen_favourite_'+counter) !== undefined)
        {
            counter++;
        }
        
        Cookies.set('texgen_favourite_'+counter, cookie, {domain: 'ebema.be', expires: 180});

        if (this.debug === true)
            console.log(cookie);

        this.message_post_favourite();
    }
    
    // Favourites read cookies
    favourites_read_cookies()
    {
        let cookies = {};

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
        {
            cookies = {
                'texgen_favourite_0': 'collection=panorama&stone_cloud_white=1&spacing=none&bond=halfsteensverband&orientation=h&date=23-10-2022',
                'texgen_favourite_1': 'collection=panorama&stone_cloud_white=1&stone_dim_grey=1&spacing=standard&color=f0f0f0&bond=halfsteensverband&orientation=h&date=23-10-2022',
                'texgen_favourite_2': 'collection=panorama&stone_cloud_white=1&spacing=spacing&color=f0f0f0&bond=halfsteensverband&orientation=h&date=23-10-2022',
                'texgen_favourite_3': 'collection=panorama&stone_whale_grey=2&spacing=spacing&color=f0f0f0&bond=halfsteensverband&orientation=h&date=23-10-2022',
                'texgen_favourite_4': 'collection=panorama&stone_cloud_white=2&stone_dim_grey=2&stone_dune_beige=1&spacing=standard&color=f0f0f0&bond=halfsteensverband&orientation=h&date=23-10-2022',
                'texgen_favourite_5': 'collection=panorama&stone_cloud_white=1&stone_dim_grey=1&spacing=none&color=f0f0f0&bond=halfsteensverband&orientation=h&date=23-10-2022'
            }
        }
        else
            cookies = Cookies.get();
        
        let cookies_enumerate = Object.entries(cookies);
        for (let i = 0; i < cookies_enumerate.length; i++)
        {
            if (cookies_enumerate[i][0].includes('texgen_favourite_', 0) === true)
            {
                if (this.cookie_read(cookies_enumerate[i][1]) === true)
                {
                    this.favourites_key.push(cookies_enumerate[i][0]);
                    this.favourites_stone_collection.push(this.cookie_stone_collection);
                    this.favourites_stone.push(this.cookie_stone);
                    this.favourites_spacing_type.push(this.cookie_spacing_type);
                    this.favourites_spacing_color.push(this.cookie_spacing_color);
                    this.favourites_bond_type.push(this.cookie_bond_type);
                    this.favourites_bond_orientation.push(this.cookie_bond_orientation);
                    this.favourites_date.push(this.cookie_date);
                }
            }
        }
    }

    // Favourites prepare textures
    favourites_prepare_textures()
    {
        if (this.favourites_stone_collection.length > 0)
        {
            for (let i = 0; i < this.favourites_stone_collection.length; i++)
            {
                let stones_indices = [];
                for (let j = 0; j < this.favourites_stone[i].length; j++)
                {
                    for (let k = 0; k < this.favourites_stone[i][j]; k++)
                    {
                        stones_indices.push(j);
                    }
                }

                let stones = [];
                if (stones_indices.length <= 8)
                {
                    for (let j = 0; j < stones_indices.length; j++)
                    {
                        stones.push(stones_indices[j]);
                    }
                }
                else
                {
                    for (let j = 0; j < 8; j++)
                    {
                        let random_index = Math.floor(Math.random()*stones_indices.length);

                        stones.push(stones_indices[random_index]);
                    }
                }

                let diffuse_source = [];
                let diffuse_image = [];
                let mixmask_source = [];
                let mixmask_image = [];

                for (let j = 0; j < stones.length; j++)
                {
                    diffuse_source.push('/texture/intro_diffuse/'+this.state.content_stone_collections[this.favourites_stone_collection[i]][1]+'_'+this.state.content_stone_types[stones[j]][0]+'/'+this.state.content_stone_collections[this.favourites_stone_collection[i]][1]+'_'+this.state.content_stone_types[stones[j]][0]+'_'+this.state.content_spacing_types[this.favourites_spacing_type[i]][0]+'_'+this.state.content_spacing_colors[this.favourites_spacing_color[i]][0]+'_'+this.state.content_bond_types[this.favourites_bond_type[i]][0]+'_'+this.state.content_bond_orientations[this.favourites_bond_orientation[i]][0]+'_diffuse.jpg');

                    diffuse_image.push(null);

                    if (stones.length > 1)
                    {
                        mixmask_source.push('/texture/intro_mixmask/'+this.state.content_stone_collections[this.favourites_stone_collection[i]][1]+'_'+this.state.content_spacing_types[this.favourites_spacing_type[i]][0]+'_'+ this.state.content_bond_types[this.favourites_bond_type[i]][0]+'_'+this.state.content_bond_orientations[this.favourites_bond_orientation[i]][0]+'_step'+stones.length+'_mixmask'+j+'.jpg');

                        mixmask_image.push(null);
                    }
                }
                
                this.favourites_diffuse_source.push(diffuse_source);
                this.favourites_diffuse_image.push(diffuse_image);
                this.favourites_mixmask_source.push(mixmask_source);
                this.favourites_mixmask_image.push(mixmask_image);
                this.favourites_stones.push(stones);
            }

            this.favourites_load_textures();
        }
        else
        {
            // No favourites; continue
            this.setState({
                favourites_loaded: true,
            });
        }
    }

    // Favourites load textures
    favourites_load_textures()
    {
        let total = 0;

        for (let i = 0; i < this.favourites_diffuse_source.length; i++)
        {
            total += this.favourites_diffuse_source[i].length;
        }

        for (let i = 0; i < this.favourites_mixmask_source.length; i++)
        {
            total += this.favourites_mixmask_source[i].length;
        }

        if (total === 0)
        {
            this.favourites_callback_counter = 0;
            this.setState({
                favourites_loaded_counter: 0,
                favourites_loaded_target: 0,
                favourites_loaded: true,
            });
        }
        else
        {
            this.favourites_callback_counter = 0;
            this.setState({
                favourites_loaded_counter: 0,
                favourites_loaded_target: total,
                favourites_loaded: false,
            }, () => {
                for (let i = 0; i < this.favourites_diffuse_source.length; i++)
                {
                    for (let j = 0; j < this.favourites_diffuse_source[i].length; j++)
                    {
                        let image;

                        image = new Image();
                        image.src = this.favourites_diffuse_source[i][j];
                        image.onload = this.favourites_callback_loaded_texture;
                        this.favourites_diffuse_image[i][j] = image;

                        if (this.debug === true)
                            console.log('Texture '+this.favourites_diffuse_source[i][j]);
                    }
                }

                for (let i = 0; i < this.favourites_mixmask_source.length; i++)
                {
                    for (let j = 0; j < this.favourites_mixmask_source[i].length; j++)
                    {
                        let image;

                        image = new Image();
                        image.src = this.favourites_mixmask_source[i][j];
                        image.onload = this.favourites_callback_loaded_texture;
                        this.favourites_mixmask_image[i][j] = image;

                        if (this.debug === true)
                            console.log('Texture '+this.favourites_mixmask_source[i][j]);
                    }
                }
            });
        }
    }
    
    // Favourites callback loaded texture
    favourites_callback_loaded_texture()
    {
        this.favourites_callback_counter++;

        this.setState({
            favourites_loaded_counter: this.favourites_callback_counter
        });

        if (this.favourites_callback_counter === this.state.favourites_loaded_target)
        {
            this.setState({
                favourites_loaded: true
            });
        }
    }
    
    // Favourites delete cookie
    favourites_delete_cookie(index)
    {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
        {
            // Do nothing
        }
        else
        {
            Cookies.remove(this.favourites_key[index], {domain: 'ebema.be'});
        }
    }

    // -------------------------------------------------------------------

    // Export filename
    export_filename()
    {
        let name = '';

        name += this.state.content_stone_collections[this.state.configurator_stone_collection][1];

        for (let i = 0; i < this.state.content_stone_types.length; i++)
        {
            // Check if this stone is in the current collection
            if (this.state.content_stone_types[i][1] === this.state.content_stone_collections[this.state.configurator_stone_collection][0])
            {
                if (this.state.configurator_stone_multi[i] > 0)
                {
                    name += '_'+this.state.content_stone_types[i][0]+'='+this.state.configurator_stone_multi[i];
                }
            }
        }

        name += '_'+this.state.content_spacing_types[this.state.configurator_spacing_type][0];

        if (this.state.configurator_spacing_type !== 0)
        {
            // Do not save color for spacing=none
            name += '_'+this.state.content_spacing_colors[this.state.configurator_spacing_color][0];
        }

        name += '_'+this.state.content_bond_types[this.state.configurator_bond_type][0];
        name += '_'+this.state.content_bond_orientations[this.state.configurator_bond_orientation][0];

        return name;
    }

    // Export texture PDF
    export_pdf(view_mode, suffix)
    {
        // Default export is a4 paper, portrait, using millimeters for units
        const document = new jsPDF({compress:true});

        // Logo
        var img_stonestyle = new Image();
        img_stonestyle.src = './pdf/logo-stonestyle.jpg';
        document.addImage(img_stonestyle, 'JPG', 15, 4, 57, 57*(372/1246));

        // Sublogo
        var img_building = new Image();
        img_building.src = './pdf/logo-building.jpg';
        document.addImage(img_building, 'JPG', 15, 24, 27, 27*(431/1906));
        
        // Line
        document.setLineWidth(0.25);
        document.setDrawColor(131, 142, 153);
        document.line(15, 36, 195, 36);

        // Subtitle
        document.setFont('ClanProMedium', 'normal');
        document.setFontSize(12);
        document.setTextColor(0, 0, 0);
        document.text(this.translate('texturegenerator'), 15, 46);
        
        // Title
        document.setFontSize(28);
        document.text(this.translate('intro_headline'), 15, 56);
        
        // Productinfo
        document.setFontSize(12);
        document.text(this.translate('function_productinfo'), 15, 70);

        // Collection
        let position_y = 80;
        let position_spacing = 6;
        document.setTextColor(131, 142, 153);
        document.text(this.translate('configurator_collection'), 15, position_y);
        document.setTextColor(0, 0, 0);
        document.text(this.state.content_stone_collections[this.state.configurator_stone_collection][2+this.translation_language_index], 80, position_y);

        // Stones
        position_y += position_spacing;
        document.setTextColor(131, 142, 153);
        document.text(this.translate('configurator_stone'), 15, position_y);
        document.setTextColor(0, 0, 0);

        let total = 0;
        for (let i = 0; i < this.state.content_stone_types.length; i++)
        {
            // Check if this stone is in the current collection
            if (this.state.content_stone_types[i][1] === this.state.content_stone_collections[this.state.configurator_stone_collection][0])
            {
                if (this.state.configurator_stone_multi[i] > 0)
                    total += this.state.configurator_stone_multi[i];
            }
        }

        for (let i = 0; i < this.state.content_stone_types.length; i++)
        {
            // Check if this stone is in the current collection
            if (this.state.content_stone_types[i][1] === this.state.content_stone_collections[this.state.configurator_stone_collection][0])
            {
                if (this.state.configurator_stone_multi[i] > 0)
                {
                    document.text(this.state.content_stone_types[i][2+this.translation_language_index]+' ('+Math.floor((this.state.configurator_stone_multi[i]/total)*100)+'%)', 80, position_y);

                    position_y += position_spacing;
                }
            }
        }

        // Correct one line
        position_y -= position_spacing;

        // Bond type
        position_y += position_spacing;
        document.setTextColor(131, 142, 153);
        document.text(this.translate('configurator_bond_type'), 15, position_y);
        document.setTextColor(0, 0, 0);
        document.text(this.state.content_bond_types[this.state.configurator_bond_type][1+this.translation_language_index], 80, position_y);

        // Orientation
        position_y += position_spacing;
        document.setTextColor(131, 142, 153);
        document.text(this.translate('configurator_orientation'), 15, position_y);
        document.setTextColor(0, 0, 0);
        document.text(this.state.content_bond_orientations[this.state.configurator_bond_orientation][1+this.translation_language_index], 80, position_y);

        // Spacing
        position_y += position_spacing;
        document.setTextColor(131, 142, 153);
        document.text(this.translate('configurator_spacing'), 15, position_y);
        document.setTextColor(0, 0, 0);
        document.text(this.state.content_spacing_types[this.state.configurator_spacing_type][1+this.translation_language_index], 80, position_y);

        // Spacing color
        position_y += position_spacing;
        let spacing_color = this.state.content_spacing_colors[this.state.configurator_spacing_color][1+this.translation_language_index];
        
        if (this.state.configurator_spacing_type === 0)
            spacing_color = this.translate('stone_spacing_none');

        document.setTextColor(131, 142, 153);
        document.text(this.translate('configurator_spacing_color'), 15, position_y);
        document.setTextColor(0, 0, 0);
        document.text(spacing_color, 80, position_y);

        // Render canvas
        position_y += position_spacing*2;
        let canvas_pdf = this.canvas_reference.current.cloneNode();
        canvas_pdf.width = 2560;
        canvas_pdf.height = 1280;

        this.canvas_render(canvas_pdf, canvas_pdf.width, canvas_pdf.height, view_mode, 32);

        var canvas_image = canvas_pdf.toDataURL('image/png');
        document.addImage(canvas_image, 'PNG', 15, position_y, 180, 180*(canvas_pdf.height/canvas_pdf.width), '', 'MEDIUM');

        // Clean
        canvas_pdf = null;
        canvas_image = null;

        // Line 2
        document.setLineWidth(0.25);
        document.setDrawColor(131, 142, 153);
        document.line(15, 274, 195, 274);
        
        // Date
        const date = new Date();
        document.setFontSize(10);
        document.setTextColor(0, 0, 0);
        document.text(date.getDate()+'-'+(date.getMonth()+1)+'-'+date.getFullYear(), 15, 281);
        
        // URL
        document.setFontSize(10);
        document.setTextColor(0, 0, 0);
        let URL = this.ip;
        URL = URL.replace('https://', '');
        document.text(URL, 195, 281, 'right');
        
        // Export
        document.save('Ebema_Stone&Style_'+this.export_filename()+'_'+suffix+'.pdf');
        
        // Tracker
        let querystring = this.configurator_get_querystring();
        this.tracker_pdf(view_mode, querystring);

        // Post querystring for marketing
        if (view_mode === 0)
            this.message_post_querystring(true, false, false);
        else
            this.message_post_querystring(false, true, false);
    }

    // -------------------------------------------------------------------

    tracker_pdf(view_mode, configuration)
    {
        const post_tracker_pdf = async() => {
            try
            {
                const data = {
                    domain: this.ip,
                    view: view_mode,
                    configuration: configuration,
                }

                await axios.post(this.ip+'/backend/api/post_tracker_pdf', data);
            }

            catch(error)
            {
                console.log(error);
            }
        }
        
        post_tracker_pdf();
    }

    // -------------------------------------------------------------------

    componentDidMount()
    {
        // Failsafe
        if (this.state.content_called === false)
        {
            this.setState({
                content_called: true
            }, () => {
                if (this.debug === true)
                    console.log('Initiate loading');

                if (this.download === false)
                {
                    if (this.favourites === false)
                    {
                        // Normal mode
                        this.content_get_intro();
                        this.content_get_stone_collections();
                        this.content_get_stone_types();
                        this.content_get_spacing_types();
                        this.content_get_spacing_colors();
                        this.content_get_bond_types();
                        this.content_get_bond_orientations();
                        this.content_get_photos();
                        this.content_get_translations();
                        this.content_get_form_clients();
                    }
                    else
                    {
                        // Favourites mode
                        this.content_get_stone_collections();
                        this.content_get_stone_types();
                        this.content_get_spacing_types();
                        this.content_get_spacing_colors();
                        this.content_get_bond_types();
                        this.content_get_bond_orientations();
                        this.content_get_translations();
                    }
                }
                else
                {
                    // Download mode
                    this.content_get_translations();
                }

                this.message_post_height();
            });
        }

        window.addEventListener('message', (event) => {
            this.message_incoming(event);
        });

        // Check if parent exists
        if (window.top !== window.self)
        {
            this.message_post_request_window_height();
        }
        else
        {
            // Set the initial window height
            this.setState({
                window_height: window.innerHeight
            });
        }
    };

    componentWillUnmount()
    {
        this.canvas_autosave_reference = null;
    }
    
    // -------------------------------------------------------------------

    // Render
    render()
    {
        let debug = '';
        if (this.debug === true)
        {
            debug = <Debug
                favourites={this.favourites}
                favourites_loaded_counter={this.state.favourites_loaded_counter}
                favourites_loaded_target={this.state.favourites_loaded_target}
                favourites_loaded={this.state.favourites_loaded}
                canvas_width={this.state.canvas_width}
                canvas_height={this.state.canvas_height}
                window_height={this.state.window_height}
                view_rows={this.state.view_rows}
                content_stone_loaded_counter={this.state.content_stone_loaded_counter}
                autosave_loaded_counter={this.state.autosave_loaded_counter}
                autosave_loaded_target={this.state.autosave_loaded_target}
                autosave_loaded={this.state.autosave_loaded}
                intro_loaded_counter={this.state.intro_loaded_counter}
                intro_loaded_target={this.state.intro_loaded_target}
                intro_loaded={this.state.intro_loaded}
                photo_current={this.state.photo_current}
                photo_size={this.state.photo_size}
                photo_loaded_counter={this.state.photo_loaded_counter}
                photo_loaded_target={this.state.photo_loaded_target}
                photo_loaded={this.state.photo_loaded}
                configurator_stone_multi={this.state.configurator_stone_multi}
                configurator_spacing_type={this.state.configurator_spacing_type}
                configurator_spacing_color={this.state.configurator_spacing_color}
                configurator_bond_type={this.state.configurator_bond_type}
                configurator_bond_orientation={this.state.configurator_bond_orientation}
                configurator_changed={this.state.configurator_changed}
                stone_textures_enabled={this.stone_textures_enabled}
                debug_stone_width_full={this.state.debug_stone_width_full}
                debug_stone_width_half={this.state.debug_stone_width_half}
                debug_stone_height={this.state.debug_stone_height}
                debug_stone_count={this.state.debug_stone_count}
                debug_page_width_mm={this.state.debug_page_width_mm}
                debug_page_height_mm={this.state.debug_page_height_mm}
                debug_page_text_count={this.state.debug_page_text_count} />
        }
         
        // Handle standalone or iframe size
        let body_style;
        if (this.state.window_height !== null)
        {
            body_style = {
                height: this.state.window_height+'px'
            }
        }
        else
        {
            body_style = {
                height: window.innerHeight+'px'
            }
        }

        if (this.download === false)
        {
            if (this.favourites === false)
            {
                // Normal mode

                // Loading
                if (this.state.intro_loaded === false && this.state.configurator_intro === true)
                {
                    return (
                        <React.Fragment>
                            <div className="body-configurator" style={body_style}>
                                <Canvas
                                    canvas_reference={this.canvas_reference}
                                    view_mode={0}
                                    function_canvas_resize={this.canvas_resize}
                                    function_canvas_queue_resize={this.canvas_queue_resize}
                                    function_configurator_disable_mobile={this.configurator_disable_mobile}
                                    show={false} />
                                
                                <div className="view-container clearfix">
                                    <LoadingIntro
                                        function_message_post_height={this.message_post_height} />
                                    {debug}
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }

                // Intro
                if (this.state.autosave_loaded === true && this.state.intro_loaded === true && this.state.configurator_intro === true)
                {
                    if (this.state.configurator_intro_start === false)
                    {
                        // Start
                        return (
                            <React.Fragment>
                                <div className="body-configurator" style={body_style}>
                                    <Canvas
                                        canvas_reference={this.canvas_reference}
                                        view_mode={0}
                                        function_canvas_resize={this.canvas_resize}
                                        function_canvas_queue_resize={this.canvas_queue_resize}
                                        function_configurator_disable_mobile={this.configurator_disable_mobile}
                                        show={false} />
                                    
                                    <div className="view-container clearfix">
                                        <Start
                                            content_spacing_colors={this.state.content_spacing_colors}
                                            autosave_found={this.autosave_found}
                                            autosave_stone_collection={this.autosave_stone_collection}
                                            autosave_stones={this.autosave_stones}
                                            autosave_spacing_type={this.autosave_spacing_type}
                                            autosave_spacing_color={this.autosave_spacing_color}
                                            autosave_bond_type={this.autosave_bond_type}
                                            autosave_bond_orientation={this.autosave_bond_orientation}
                                            autosave_diffuse_image={this.autosave_diffuse_image}
                                            autosave_mixmask_image={this.autosave_mixmask_image}
                                            function_translate={this.translate}
                                            function_intro_start_existing={this.intro_start_existing}
                                            function_configurator_start_blanco={this.configurator_start_blanco}
                                            function_configurator_start_autosave={this.configurator_start_autosave} />
                                        {debug}
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    }
                    else
                    {
                        // Items
                        return (
                            <React.Fragment>
                                <div className="body-configurator" style={body_style}>
                                    <div className="view-intro-items-container clearfix">
                                        <Canvas
                                            canvas_reference={this.canvas_reference} 
                                            view_mode={0}
                                            function_canvas_resize={this.canvas_resize}
                                            function_canvas_queue_resize={this.canvas_queue_resize}
                                            function_configurator_disable_mobile={this.configurator_disable_mobile}
                                            show={false} />

                                        <Intro
                                            translation_language_index={this.translation_language_index}
                                            content_intro={this.state.content_intro}
                                            content_spacing_colors={this.state.content_spacing_colors}
                                            content_spacing_types={this.state.content_spacing_types}
                                            intro_diffuse_image={this.intro_diffuse_image}
                                            intro_mixmask_image={this.intro_mixmask_image}
                                            configurator_changed={this.state.configurator_changed}
                                            function_translate={this.translate}
                                            function_configurator_start={this.configurator_start}
                                            function_configurator_start_blanco={this.configurator_start_blanco} 
                                            function_configurator_start_continue={this.configurator_start_continue} />

                                        {debug}
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    }
                }
            
                if (this.state.configurator_intro === false)
                {
                    // Configurator

                    let configurator_style = {};
                    if (this.state.view_mode === 0)
                    {
                        configurator_style = {
                            marginLeft: '16px'
                        }
                    }
                    
                    return (
                        <React.Fragment>
                            <div className="body-configurator" style={body_style}>
                                <Canvas
                                    canvas_reference={this.canvas_reference}
                                    view_mode={this.state.view_mode}
                                    window_height={this.state.window_height}
                                    function_canvas_resize={this.canvas_resize}
                                    function_canvas_queue_resize={this.canvas_queue_resize}
                                    function_configurator_disable_mobile={this.configurator_disable_mobile}
                                    show={true} />
                                
                                <div className="view-container clearfix">
                                    <div style={configurator_style}>
                                        <Configurator
                                            translation_language_index={this.translation_language_index}
                                            content_stone_collections={this.state.content_stone_collections}
                                            content_stone_types={this.state.content_stone_types}
                                            content_spacing_types={this.state.content_spacing_types}
                                            content_spacing_colors={this.state.content_spacing_colors}
                                            content_bond_types={this.state.content_bond_types}
                                            content_bond_orientations={this.state.content_bond_orientations}
                                            configurator_stone_collection={this.state.configurator_stone_collection}
                                            configurator_stone_multi={this.state.configurator_stone_multi}
                                            configurator_spacing_type={this.state.configurator_spacing_type}
                                            configurator_spacing_color={this.state.configurator_spacing_color}
                                            configurator_bond_type={this.state.configurator_bond_type}
                                            configurator_bond_orientation={this.state.configurator_bond_orientation}
                                            configurator_mobile={this.state.configurator_mobile}
                                            view_mode={this.state.view_mode}
                                            function_translate={this.translate}
                                            function_configurator_stone_collection={this.configurator_stone_collection}
                                            function_configurator_stone_select_multi_increase={this.configurator_stone_select_multi_increase}
                                            function_configurator_stone_select_multi_decrease={this.configurator_stone_select_multi_decrease}
                                            function_configurator_spacing_type={this.configurator_spacing_type}
                                            function_configurator_spacing_color={this.configurator_spacing_color}
                                            function_configurator_bond_type={this.configurator_bond_type}
                                            function_configurator_bond_orientation={this.configurator_bond_orientation}
                                            function_configurator_adapt_dropdowns={this.configurator_adapt_dropdowns}
                                            function_configurator_enable_mobile={this.configurator_enable_mobile}
                                            function_configurator_disable_mobile={this.configurator_disable_mobile}
                                            function_view_back_to_intro_items={this.view_back_to_intro_items}
                                            function_popup_open={this.popup_open} />
                                    </div>

                                    <View
                                        translation_language_index={this.translation_language_index}
                                        view_mode={this.state.view_mode}
                                        view_rows_min={this.state.view_rows_min}
                                        view_rows_max={this.state.view_rows_max}
                                        view_rows={this.state.view_rows}
                                        window_height={this.state.window_height}
                                        content_photos={this.state.content_photos}
                                        content_loaded={this.state.content_loaded}
                                        photo_current={this.state.photo_current}
                                        photo_size={this.state.photo_size}
                                        photo_loaded={this.state.photo_loaded}
                                        photo_first={this.state.photo_first}
                                        function_translate={this.translate}
                                        function_configurator_check_valid={this.configurator_check_valid}
                                        function_view_mode={this.view_mode}
                                        function_view_rows_zoomout={this.view_rows_zoomout}
                                        function_view_rows_zoomin={this.view_rows_zoomin}
                                        function_photo_select={this.photo_select}
                                        function_photo_previous={this.photo_previous}
                                        function_photo_next={this.photo_next}
                                        function_photo_size={this.photo_size} />

                                    <Function
                                        content_stone_collections={this.state.content_stone_collections}
                                        content_stone_types={this.state.content_stone_types}
                                        configurator_stone_collection={this.state.configurator_stone_collection}
                                        configurator_stone_multi={this.state.configurator_stone_multi}
                                        view_mode={this.state.view_mode}
                                        window_height={this.state.window_height}
                                        function_translate={this.translate}
                                        function_configurator_check_valid={this.configurator_check_valid}
                                        function_popup_open={this.popup_open}
                                        function_favourites_save_cookie={this.favourites_save_cookie} />

                                    <Warning
                                        view_mode={this.state.view_mode}
                                        content_loaded={this.state.content_loaded}
                                        content_bond_types={this.state.content_bond_types}
                                        configurator_intro={this.state.configurator_intro}
                                        configurator_bond_type={this.state.configurator_bond_type}
                                        configurator_bond_orientation={this.state.configurator_bond_orientation}
                                        function_translate={this.translate}
                                        function_stone_check_textures_loaded={this.stone_check_textures_loaded}/>
                                    {debug}
                                </div>
                                
                                <Popup
                                    ip={this.ip}
                                    translation_language_index={this.translation_language_index}
                                    content_stone_collections={this.state.content_stone_collections}
                                    content_stone_types={this.state.content_stone_types}
                                    content_form_clients={this.state.content_form_clients}
                                    configurator_stone_collection={this.state.configurator_stone_collection}
                                    configurator_stone_multi={this.state.configurator_stone_multi}     
                                    photo_loaded={this.state.photo_loaded}
                                    popup_current={this.state.popup_current}
                                    view_mode={this.state.view_mode}
                                    window_height={this.state.window_height}
                                    function_translate={this.translate}
                                    function_configurator_get_querystring={this.configurator_get_querystring}
                                    function_view_mode={this.view_mode}
                                    function_popup_open={this.popup_open}
                                    function_popup_close={this.popup_close}
                                    function_export_pdf={this.export_pdf}
                                    function_message_post_querystring={this.message_post_querystring} />
                            </div>
                            <div className="body-footer">
                                <Footer
                                    translation_language_index={this.translation_language_index}
                                    content_stone_collections={this.state.content_stone_collections}
                                    content_stone_types={this.state.content_stone_types}
                                    content_spacing_types={this.state.content_spacing_types}
                                    content_spacing_colors={this.state.content_spacing_colors}
                                    content_bond_types={this.state.content_bond_types}
                                    content_bond_orientations={this.state.content_bond_orientations}
                                    configurator_stone_collection={this.state.configurator_stone_collection}
                                    configurator_stone_multi={this.state.configurator_stone_multi}
                                    configurator_spacing_type={this.state.configurator_spacing_type}
                                    configurator_spacing_color={this.state.configurator_spacing_color}
                                    configurator_bond_type={this.state.configurator_bond_type}
                                    configurator_bond_orientation={this.state.configurator_bond_orientation}
                                    function_configurator_check_valid={this.configurator_check_valid}
                                    function_favourites_save_cookie={this.favourites_save_cookie}
                                    function_translate={this.translate}
                                    function_popup_open={this.popup_open} />
                            </div>
                        </React.Fragment>
                    );
                }
            }
            else
            {
                // Favourites mode

                // Loading
                if (this.state.favourites_loaded === false)
                {
                    return (
                        <React.Fragment>
                            <div className="body-configurator">
                                <div className="view-container clearfix">
                                    <LoadingFavourite
                                        function_message_post_height={this.message_post_height} />
                                    {debug}
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }
                else
                {
                    return (
                        <React.Fragment>
                            <div className="body-configurator">
                                <Favourites
                                    ip={this.ip}
                                    content_stone_collections={this.state.content_stone_collections}
                                    content_stone_types={this.state.content_stone_types}
                                    content_spacing_types={this.state.content_spacing_types}
                                    content_spacing_colors={this.state.content_spacing_colors}
                                    content_bond_types={this.state.content_bond_types}
                                    content_bond_orientations={this.state.content_bond_orientations}
                                    favourites_stone_collection={this.favourites_stone_collection}
                                    favourites_stone={this.favourites_stone}
                                    favourites_spacing_type={this.favourites_spacing_type}
                                    favourites_spacing_color={this.favourites_spacing_color}
                                    favourites_bond_type={this.favourites_bond_type}
                                    favourites_bond_orientation={this.favourites_bond_orientation}
                                    favourites_date={this.favourites_date}
                                    favourites_diffuse_image={this.favourites_diffuse_image}
                                    favourites_mixmask_image={this.favourites_mixmask_image}
                                    function_message_post_height={this.message_post_height}
                                    function_favourites_delete_cookie={this.favourites_delete_cookie}
                                    function_translate={this.translate} />
                                {debug}
                            </div>
                        </React.Fragment>
                    );
                }
            } 
        }
        else
        {
            // Download mode

            return (
                <React.Fragment>
                    <div className="body-configurator">
                        <Download
                            ip={this.ip}
                            function_translate={this.translate} />
                        {debug}
                    </div>
                </React.Fragment>
            );
        }

        // Failsafe
        return (
            <React.Fragment>
            </React.Fragment>
        );
    }
}

const app = ReactDOM.createRoot(document.getElementById('app'));
app.render(
    <App />
);